<mat-nav-list>
    <a *ngFor="let taxCreditUsage of taxCreditUsages" mat-list-item [routerLink]="['/tax-credit-usage', taxCreditUsage.id]">
        <h3 matLine><span *ngIf="taxCreditUsage.internalId">{{taxCreditUsage.internalId}} </span></h3>
        <p matLine>Tax Credit Usage - <b>{{taxCreditUsage.type}}</b> </p>
        <p matLine>Project Module - <b>{{taxCreditUsage.ProjectAsset?.name}}</b></p>
        <p matLine>Project Module Type - <b>{{taxCreditUsage.ProjectAsset?.type}}</b></p>
        <p matLine>Amount - <em>{{taxCreditUsage.amount}}</em> </p>
        <p matLine>TaxYear - <em>{{taxCreditUsage.taxYear}}</em> </p>
        <p matLine>TaxState - <em>{{taxCreditUsage.taxState}}</em> </p>
        <p matLine>Comment - <em>{{taxCreditUsage.comment}}</em> </p>
        <p matLine>Client - <em>{{taxCreditUsage.ProjectAsset?.Project?.Client?.name}}</em></p>
        <p matLine>Created - <em>{{taxCreditUsage.createdAt | date:'medium'}}</em> </p>
        <p matLine>Updated - <em>{{taxCreditUsage.updatedAt | date:'medium'}}</em> </p>   
    </a>
</mat-nav-list>

<button mat-fab class="mat-fab-bottom-right" color="accent" [routerLink]="['/newtax-credit-usage']">
    <mat-icon>add</mat-icon>
</button>
