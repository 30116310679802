import { Component, OnInit } from '@angular/core';
import {
  APIService,
  ListProjectAssetsQuery,
  ListProjectsQuery,
  ListTaxCreditNotesQuery,
  OnUpdateProjectAssetSubscription,
  Project,
  TaxCreditNote,
} from 'src/app/API.service';
import { Router } from '@angular/router';
import { from, Observable, Subscription } from 'rxjs';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { NoticebarService } from '../services/noticebar.service';
import { FormControl } from '@angular/forms';
import { S3Service } from '../services/s3.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-irc41-report',
  templateUrl: './irc41-report.component.html',
  styleUrls: ['./irc41-report.component.css'],
})
export class Irc41ReportComponent implements OnInit {
  projectAssets: any[];
  projects: any[];
  project: Project;
  taxCreditNotes: TaxCreditNote[];
  projectTaxCreditNotes: any[];
  imageUrl: any;
  projectImageUrl: any;

  subscription: Subscription;
  projectAssetsString: string;
  projectID = new FormControl();

  constructor(
    private router: Router,
    private api: APIService,
    private noticeBar: NoticebarService,
    public s3service: S3Service
  ) {}

  async ngOnInit(): Promise<void> {
    // this.listProjects();
    // this.listProjectAssets();
    this.listTaxCreditNotes();
    await this.getClientImageUrl();

    this.projectID.valueChanges
      .pipe(switchMap((projectID) => from(this.api.GetProject(projectID))))
      .subscribe((project) => {
        console.log('In value change', project);
        this.getProjectImageUrl(this.projectID.value);
      });
    //console.log(await this.getProjectImageUrl('123'));
  }

  listTaxCreditNotes() {
    this.api.ListTaxCreditNotes().then((list: ListTaxCreditNotesQuery) => {
      // if (list.items.length == 0) {
      //   this.router.navigate(['newtax-credit-note']);
      //   return;
      // }
      this.taxCreditNotes = list.items.sort(this.byCreatedAt);
      console.log(this.taxCreditNotes);
      console.log(
        this.taxCreditNotes.map((item) => item.ProjectAsset.Project.name)
      );
    });
  }

  // filterTaxCreditNotes(projectID) {
  //   console.log(projectID);
  //   return this.taxCreditNotes.filter(
  //     (item) => item.ProjectAsset.Project.id == projectID
  //   );
  // }
  filterTaxCreditNotes(projectID, irc41Category) {
    console.log(projectID);
    return this.taxCreditNotes.filter(
      (item) =>
        item.ProjectAsset.Project.id == projectID &&
        item.irc41Category == irc41Category
    );
  }
  // getProjectImageName(projectID) {
  //   console.log(projectID);
  //   return this.taxCreditNotes
  //     .filter((item) => item.ProjectAsset.Project.id == projectID)[0]
  //     .ProjectAsset?.Project?.name.replace(/\s+/g, '');
  // }

  // async getProjectImageUrl1(projectID) {
  //   this.projectImageUrl = await this.s3service.getImageURL(
  //     'ProjectOne.jpg',
  //     'us-east-2:678b5a7a-079e-4c45-9eeb-65220fff4368'
  //   );
  //   return this.projectImageUrl;
  // }

  async getProjectImageUrl(projectID) {
    console.log(projectID);
    console.log(this.taxCreditNotes);
    const s3fileImage = this.taxCreditNotes.filter(
      (item) => item.ProjectAsset.Project.id == projectID
    )[0].ProjectAsset?.Project?.s3fileImage;
    console.log(s3fileImage);
    this.projectImageUrl = await this.s3service.getImageURL(
      s3fileImage.s3key,
      s3fileImage.s3IdentityId
    );
    return this.projectImageUrl;
  }

  getProjectName(projectID) {
    console.log(projectID);
    const projectName = this.taxCreditNotes.filter(
      (item) => item.ProjectAsset.Project.id == projectID
    )[0].ProjectAsset?.Project?.name;
    return projectName;
  }

  getProjectDescription(projectID) {
    console.log(projectID);
    const projectDescription = this.taxCreditNotes.filter(
      (item) => item.ProjectAsset.Project.id == projectID
    )[0].ProjectAsset?.Project?.description;
    return projectDescription;
  }

  createProjectTaxCreditNotes(projectID) {
    console.log(projectID);
    this.projectTaxCreditNotes = this.taxCreditNotes.filter(
      (item) => item.ProjectAsset.Project.id == projectID
    );
  }
  async getClientImageUrl() {
    this.imageUrl = await this.s3service.getImageURL(
      'DevelopmentLifeCycle.jpg',
      'us-east-2:678b5a7a-079e-4c45-9eeb-65220fff4368'
    );
  }
  print() {
    window.print();
  }
  // sort by createdAt, newest first
  byCreatedAt(a, b) {
    return a.createdAt < b.createdAt ? 1 : -1;
  }
}
