import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { APIService, ListProjectsQuery, OnUpdateProjectSubscription, Project } from 'src/app/API.service';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//import { Project } from 'src/models';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements /*OnInit,*/ AfterViewInit {

  projects: Project[];
  subscription: Subscription;
  displayedColumns =  ["internalId", "name", "contactName", "clientName", "created", "updated"];
  dataSource: MatTableDataSource<Project>; 
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router,
    private api: APIService) {
      //this.listProjects();
      //this.dataSource = new MatTableDataSource(this.projects);
     }

  ngAfterViewInit() {
    this.listProjects();
    this.dataSource = new MatTableDataSource(this.projects);
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    //this.listenChanges();
    //this.listProjects();
    //console.log(this.projects);
    //console.log(this.dataSource);

  }
  
  listProjects() {
    this.api.ListProjects().then((list: ListProjectsQuery) => {
      if (list.items.length == 0) {
        this.router.navigate(['newproject']);
        return;
      }
      this.projects = list.items.sort(this.byCreatedAt);
      this.dataSource = new MatTableDataSource(this.projects);
      console.log(this.projects);
      this.dataSource.sort = this.sort;
    });
  }

  // listen changes on bills
  listenChanges() {
    Auth.currentAuthenticatedUser().then(user => {
      const OnUpdateProjectListener: Observable<any> = API.graphql(
        graphqlOperation(
          `subscription OnUpdateProject($owner: String!) {
            onUpdateProject(owner: $owner) {
              __typename
              id
              internalId
              name 
              contactName
              clientID
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                Projects {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              createdAt
              owner
              updatedAt
              ProjectAssets {
                 __typename
                 items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  createdAt
                  updatedAt
                  owner
                 } 
                 nextToken
              } 
            }
          }`, { owner: user.username }
        )
      ) as any;
      this.subscription = OnUpdateProjectListener.subscribe(() => {
        this.listProjects();
      });
    });
  }

/*********

// listen changes on bills
  listenChanges() {
    Auth.currentAuthenticatedUser().then(user => {
      const OnUpdateBillListener: Observable<any> = API.graphql(
        graphqlOperation(
          `subscription OnUpdateBill($owner: String!) {
            onUpdateBill(owner: $owner) {
              __typename
              id
              serialnum
              title
              customerID
              customer {
                __typename
                id
                name
                address
                siret
                bills {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              lines {
                __typename
                items {
                  __typename
                  id
                  billID
                  title
                  quantity
                  cost
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              owner
              updatedAt
            }
          }`, { owner: user.username }
        )
      ) as any;
      this.subscription = OnUpdateBillListener.subscribe(() => {
        this.listBills();
      });
    });
  }

*********/
  // sort by createdAt, newest first
  byCreatedAt(a, b) {
    return a.createdAt < b.createdAt ? 1 : -1;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
