import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService, GetClientNoteQuery } from 'src/app/API.service';
import { NoticebarService } from '../services/noticebar.service';

@Component({
  selector: 'app-client-note',
  templateUrl: './client-note.component.html',
  styleUrls: ['./client-note.component.css']
})
export class ClientNoteComponent implements OnInit {

  editMode: boolean;
  form: FormGroup;
  errorString: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: APIService,
    private noticeBar: NoticebarService) {}


  ngOnInit(): void {
    const now = new Date(); 
    const iid = "CN-" + formatDate(now, "yyyy-MM-dd-HH-mm-ss-SSS", "en-US");

    this.form = new FormGroup({
      id: new FormControl(null),
      internalId: new FormControl(iid, [Validators.required]),
      clientID: new FormControl(null),
      note: new FormControl(null, [Validators.required]),
    });

    this.route.params.subscribe(params => {
      this.editMode = 'id' in params;
      if (this.editMode) {
        this.loadClientNote(params['id']);
      }
    });
  }

  loadClientNote(id: string) {
    this.api.GetClientNote(id).then((clientNote: GetClientNoteQuery) => {
      this.form.patchValue({
        id: clientNote.id,
        internalId: clientNote.internalId,
        clientID: clientNote.clientID,
        note: clientNote.note,
      })
    });
  }
  async getClientName(id: string) {
    return this.api.GetClient(id).then(client => client.name)
  }

  async add() {
    if (this.form.status === 'VALID') {
      const clientName = await this.getClientName(this.form.value.clientID);
      console.log(this.form.value);
      const clientNoteData = {
        ...this.form.value,
        ...{ editGroup : "irc41-" + clientName + "-edit" , viewGroup : "irc41-" + clientName + "-view"}
      }
      this.api.CreateClientNote(clientNoteData).then(() => {
        this.router.navigate(['client-notes']);
      }).catch(err => {
        console.error("Unable to add");
        console.error(err);
      })
    }
  }

  update() {
    if (this.form.status === 'VALID') {
      this.api.UpdateClientNote(this.form.value)
      .then(() => {
        this.router.navigate(['client-notes']);
      })
      .catch(err => this.noticeBar.openNoticeBar(err))
    }
  }

  delete() {
    this.api.DeleteClientNote({ id: this.form.value['id'] }).then(() => {
      this.router.navigate(['client-notes']);
    })
    .catch(err => this.noticeBar.openNoticeBar(err))
  }
}
