import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { S3Service } from '../services/s3.service';
import { APIService, GetS3FileQuery, S3File } from 'src/app/API.service';
import { NoticebarService } from '../services/noticebar.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css'],
})
export class FileManagerComponent implements OnInit {
  selectedFile: File;
  identityId: string;
  clientID: FormControl;
  constructor(
    private s3Service: S3Service,
    private api: APIService,
    private noticeBar: NoticebarService
  ) {}

  ngOnInit(): void {
    this.s3Service.getIdentityId().subscribe((data) => {
      this.identityId = data;
      console.log(data);
    });
    this.clientID = new FormControl();
  }
  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }
  async getClientName(id: string) {
    return this.api.GetClient(id).then((client) => client.name);
  }
  // {key: "2021-06-06-12-06-59-829AdvantaRandDReports-DevelopmentLifeCycle.jpg"}
  async onSubmit() {
    const clientName = await this.getClientName(this.clientID.value);

    const fileName =
      formatDate(new Date(), 'yyyy-MM-dd-HH-mm-ss-SSS', 'en-US') +
      this.selectedFile.name;
    const s3response = await this.s3Service.uploadProtectedFile(
      fileName,
      this.selectedFile
    );
    const s3Data = {
      internalId: 'F-' + formatDate(Date(), 'yyyy-MM-dd-HH-mm-ss-SSS', 'en-US'),
      s3key: fileName,
      s3IdentityId: this.identityId,
      editGroup: 'irc41-' + clientName + '-edit',
      viewGroup: 'irc41-' + clientName + '-view',
    };
    this.api.CreateS3File(s3Data);
    console.log(s3response);
  }
}
