<div class="donotprint">
  <app-project-select [formControl]="projectID"></app-project-select>
</div>
<div class="report-preface" *ngIf="projectID.value != null">
  <h1>{{ getProjectName(projectID.value) }}</h1>

  <h2>Background and Goals for the Project</h2>
  <div class="note-with-newlines">
    <p>{{ getProjectDescription(projectID.value) }}</p>
  </div>

  <h3>Development Life Cycle</h3>
  <img
    src="/assets/images/AdvantaRandDReports-DevelopmentLifeCycle.jpg"
    alt="process-image"
  />

  <!-- <img [src]="imageUrl" alt="process-image" /> -->

  <h3>Business Modeling:</h3>
  <p>
    The Business Modelling discipline focuses on efforts to understand the
    organization, its processes, and the problem domain. The discipline focuses
    on understanding the following factors and how they may impact or relate to
    the software being considered:
  </p>
  <ul>
    <li>Enterprise business rules</li>
    <li>Enterprise business process model</li>
    <li>Enterprise domain model</li>
    <li>Enterprise mission statement</li>
    <li>Enterprise vision</li>
    <li>Organization model</li>
  </ul>
  <p>
    Business Modeling phase business processes are defined using software
    features or user cases.
  </p>
  <p></p>

  <h3>Requirements:</h3>
  <p>
    The goal of the Requirements workflow is to describe what the system should
    do and allows the developers and the customer to agree on that description.
    Actors are identified, representing the users, and any other system that may
    interact with the system being developed. User Stories are identified,
    representing the behavior of the system. Because user stories are developed
    according to the actor's needs, the system is more likely to be relevant to
    the users.
  </p>
  <h3>Analysis & Design:</h3>
  <p>
    The requirements are analyzed from a solution design perspective and
    specific activities that are part of this phase include:
  </p>
  <ul>
    <li>Understanding and analyzing the requirements for the system</li>
    <li>Defining a candidate architecture for a system</li>
    <li>
      Constructing a proof-of-concept or prototype to validate a candidate
      architecture
    </li>
    <li>Design of components, services, and/or modules</li>
    <li>Design of interfaces (network, user, and databases)</li>
  </ul>
  <p>
    The design model consists of design classes structured into design packages
    and design subsystems with well-defined interfaces, representing what will
    become components (APIs) in the implementation. It also contains
    descriptions of how objects (or APIs) of these design classes collaborate to
    perform use stories.
  </p>

  <h3>Test:</h3>
  <p>The purposes of testing are:</p>
  <ul>
    <li>To verify the interaction between objects.</li>
    <li>To verify the proper integration of all components of the software.</li>
    <li>To verify that all requirements have been correctly implemented.</li>
    <li>
      To identify and ensure defects are addressed prior to the deployment of
      the software.
    </li>
  </ul>
  <p>
    Tests are carried out along three quality dimensions reliability,
    functionality, application performance and system performance. For each of
    these quality dimensions, the process describes how you go through the test
    lifecycle of planning, design, implementation, execution and evaluation.
    Test automation is especially important using an iterative approach, to
    allow regression testing at the end of each iteration, as well as for each
    new version of the product.
  </p>

  <h3>Experiment:</h3>
  <p>
    Experiment phase covers activities related to investigating new business
    processes, analyzing data for potential new business scenarios, undertaking
    tasks involving technical uncertainties, understanding, and improving the
    performance, reliability, and quality aspects of the system.
  </p>

  <h3>Evaluate:</h3>
  <p>
    Evaluation phase deliberates on the results documented in the experiment
    phases. The purpose of the evaluation is to eliminate alternatives and
    isolate alternatives that can be incorporated to the customer facing
    products.
  </p>
  <h3>Deployment:</h3>
  <p>
    The purpose of the deployment workflow is to successfully produce product
    releases and deliver the software to its end users. It covers a wide range
    of activities including:
  </p>
  <ul>
    <li>Continuous Integration and Deployment (CI/CD)</li>
    <li>Producing external releases of the software.</li>
    <li>Providing help and assistance to users.</li>
    <li>Migration of existing software or data.</li>
    <li>Formal acceptance.</li>
  </ul>

  <!-- <img
    src="/assets/images/{{ getProjectImageName(projectID.value) }}.jpg"
    alt="process-image"
  /> -->
  <div class="projectImage" *ngIf="projectImageUrl != null">
    <img [src]="projectImageUrl" alt="process-image" />
  </div>
  <!-- <img src="{{ getProjectImageUrl(projectID.value) }}" alt="process-image" >  -->

  <!-- <p>img src {{ projectImageUrl }}</p> -->

  <h2>IRC §41 New or Improved Business Component</h2>

  <div
    class="tax-credit-notes"
    *ngFor="
      let taxCreditNote of filterTaxCreditNotes(
        projectID.value,
        'NewProductProcess'
      )
    "
  >
    <div *ngIf="taxCreditNote.ProjectAsset.Project.id == projectID.value">
      <h3>{{ taxCreditNote.ProjectAsset?.name }} :</h3>
      <div class="note-with-newlines">
        <p>{{ taxCreditNote.note }}</p>
      </div>
    </div>
  </div>

  <h2>IRC §41 Technological in Nature</h2>
  <ul>
    <li>
      <b>Conceptual and formal models.</b> This principle involves the various
      ways of formalizing, characterizing, visualizing, and thinking about an
      idea or problem. The design of the software solution that would yield the
      desired results would require activities relying on this principle of
      computer science. The initiatives of this project could not have been
      conducted without first developing a conceptual framework and models.
      These served as the initial hypotheses that were the basis of the initial
      experimentation. These hypotheses were revised as testing and analysis
      activities were conducted.
    </li>
    <li>
      <b>Consistency and completeness.</b> This principle relates to the
      concrete realization of the concepts of consistency and completeness in
      computing, including related concepts such as correctness, robustness, and
      reliability. Specifically, consistency relates to the consistency of a set
      of theories that serve as a formal specification, the consistency of those
      theories to observed fact under testing, and the internal consistency of a
      language or interface design. Correctness is the consistency of a
      component or system behavior to stated specifications. Completeness
      includes the ability of the theories and design to capture all desired
      behaviors, the functional adequacy of software, and the ability of a
      system to behave to specifications under error conditions and
      unanticipated situations. The designers would need to develop a system
      that performed up to the stated specifications at the desired performance
      and be robust enough to perform when it encountered unanticipated data or
      situations. Without this principle, the software would not perform
      consistently across different platforms and for different customers.
    </li>
    <li>
      <b>Efficiency.</b> This principle is represented by the need to develop
      software that is conscious of scarce resources such as space and
      processing power. This project included both the need to optimize
      processing power due to the vast amount of throughput as well as database
      space due to the large volume of data required to be stored. The engineers
      needed to consider the efficiency of the performance of the modules. If
      not, the processing and storage capabilities would likely cause the
      modules to be so slow as to be effectively useless.
    </li>
    <li>
      <b>Complexity of Large Problems.</b> principle involves the effects of
      non-linear increases in complexity as the size of a problem grows. It
      involves scaling and handling ever increasing volumes of data as
      functionalities and processes are developed. The developers encountered
      this principle and used the tools available in developing the new
      functionalities of these modules. These modules and the modules developed
      before them consist of a comprehensive program that is required to be seen
      as one system. The interdependence of the functionalities and systems are
      required to be seamless and efficient and are so numerous that each single
      addition or improvement must be developed to be compatible. Each new
      program module grows in complexity exponentially.
    </li>
  </ul>

  <div
    class="tax-credit-notes"
    *ngFor="
      let taxCreditNote of filterTaxCreditNotes(projectID.value, 'HardScience')
    "
  >
    <div *ngIf="taxCreditNote.ProjectAsset.Project.id == projectID.value">
      <h3>{{ taxCreditNote.ProjectAsset?.name }} :</h3>
      <div class="note-with-newlines">
        <p>{{ taxCreditNote.note }}</p>
      </div>
    </div>
  </div>

  <h2>IRC §41 Technical Uncertainties</h2>

  <div
    class="tax-credit-notes"
    *ngFor="
      let taxCreditNote of filterTaxCreditNotes(
        projectID.value,
        'EliminationOfUncertainty'
      )
    "
  >
    <div *ngIf="taxCreditNote.ProjectAsset.Project.id == projectID.value">
      <h3>{{ taxCreditNote.ProjectAsset?.name }} :</h3>
      <div class="note-with-newlines">
        <p>{{ taxCreditNote.note }}</p>
      </div>
    </div>
  </div>

  <h2>IRC §41 Process of Experimentation</h2>

  <div
    class="tax-credit-notes"
    *ngFor="
      let taxCreditNote of filterTaxCreditNotes(
        projectID.value,
        'ProcessOfExperimentation'
      )
    "
  >
    <div *ngIf="taxCreditNote.ProjectAsset.Project.id == projectID.value">
      <h3>{{ taxCreditNote.ProjectAsset?.name }} :</h3>
      <div class="note-with-newlines">
        <p>{{ taxCreditNote.note }}</p>
      </div>
    </div>
  </div>
  <div class="donotprint">
    <button mat-button color="accent" (click)="print()">Print</button>
  </div>
</div>
