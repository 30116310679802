import { Component, OnInit, OnDestroy } from '@angular/core';
import { APIService, ListClientNotesQuery, OnUpdateClientNoteSubscription } from 'src/app/API.service';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { API, graphqlOperation, Auth } from 'aws-amplify';

@Component({
  selector: 'app-client-notes',
  templateUrl: './client-notes.component.html',
  styleUrls: ['./client-notes.component.css']
})
export class ClientNotesComponent implements OnInit {

  clientNotes: any[];
  subscription: Subscription;

  constructor(
    private router: Router,
    private api: APIService) { }

  ngOnInit(): void {
    //this.listenChanges();
    this.listClientNotes();
  }

  listClientNotes() {
    this.api.ListClientNotes().then((list: ListClientNotesQuery) => {
      if (list.items.length == 0) {
        this.router.navigate(['newclient-note']);
        return;
      }
      this.clientNotes = list.items.sort(this.byCreatedAt);
    });
  }

  // sort by createdAt, newest first
  byCreatedAt(a, b) {
    return a.createdAt < b.createdAt ? 1 : -1;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}