import { BrowserModule } from '@angular/platform-browser';
import { NgModule, forwardRef } from '@angular/core';

/* import AmplifyUIAngularModule  */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';

import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSnackBarModule} from '@angular/material/snack-bar';


/* new form imports */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientComponent } from './client/client.component';
import { ClientsComponent } from './clients/clients.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatButtonModule} from '@angular/material/button';
import { MatListModule} from '@angular/material/list';
import { MatIconModule} from '@angular/material/icon';
import { MatInputModule} from '@angular/material/input';
import { MatSelectModule} from '@angular/material/select';
import { ProjectComponent } from './project/project.component';
import { ClientSelectComponent } from './client-select/client-select.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectAssetComponent } from './project-asset/project-asset.component';
import { ProjectAssetsComponent } from './project-assets/project-assets.component';
import { TaxCreditNoteComponent } from './tax-credit-note/tax-credit-note.component';
import { TaxCreditNotesComponent } from './tax-credit-notes/tax-credit-notes.component';
import { ProjectSelectComponent } from './project-select/project-select.component';
import { ProjectAssetSelectComponent } from './project-asset-select/project-asset-select.component';
import { ProjectAssetBudgetComponent } from './project-asset-budget/project-asset-budget.component';
import { ProjectAssetBudgetsComponent } from './project-asset-budgets/project-asset-budgets.component';
import { TaxCreditUsageComponent } from './tax-credit-usage/tax-credit-usage.component';
import { TaxCreditUsagesComponent } from './tax-credit-usages/tax-credit-usages.component';
import { ClientNoteComponent } from './client-note/client-note.component';
import { ClientNotesComponent } from './client-notes/client-notes.component';
import { DownloadClientComponent } from './download-client/download-client.component';
import { JunglegymComponent } from './junglegym/junglegym.component';
import { ProjectBudgetComponent } from './project-budget/project-budget.component';
import { ProjectBudgetsComponent } from './project-budgets/project-budgets.component';
import { ProjectTaxCreditUsagesComponent } from './project-tax-credit-usages/project-tax-credit-usages.component';
import { ProjectTaxCreditUsageComponent } from './project-tax-credit-usage/project-tax-credit-usage.component';
import { Irc41ReportComponent } from './irc41-report/irc41-report.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { S3fileSelectComponent } from './s3file-select/s3file-select.component';
import { S3fileComponent } from './s3file/s3file.component';
import { S3filesComponent } from './s3files/s3files.component';

@NgModule({
  declarations: [
    AppComponent,
    ClientComponent,
    ClientsComponent,
    ProjectComponent,
    ClientSelectComponent,
    ProjectsComponent,
    ProjectAssetComponent,
    ProjectAssetsComponent,
    TaxCreditNoteComponent,
    TaxCreditNotesComponent,
    ProjectSelectComponent,
    ProjectAssetSelectComponent,
    ProjectAssetBudgetComponent,
    ProjectAssetBudgetsComponent,
    TaxCreditUsageComponent,
    TaxCreditUsagesComponent,
    ClientNoteComponent,
    ClientNotesComponent,
    DownloadClientComponent,
    JunglegymComponent,
    ProjectBudgetComponent,
    ProjectBudgetsComponent,
    ProjectTaxCreditUsagesComponent,
    ProjectTaxCreditUsageComponent,
    Irc41ReportComponent,
    FileManagerComponent,
    S3fileSelectComponent,
    S3fileComponent,
    S3filesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    /* configure app with AmplifyUIAngularModule */
    AmplifyUIAngularModule,
    /* configuring form modules */
    FormsModule,
    ReactiveFormsModule,
    // Material
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    /* table */
    MatTableModule,
    MatCardModule,
    MatSortModule,
    MatPaginatorModule,
    MatSnackBarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
