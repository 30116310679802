import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { APIService, ListS3FilesQuery } from 'src/app/API.service';

@Component({
  selector: 'app-s3file-select',
  templateUrl: './s3file-select.component.html',
  styleUrls: ['./s3file-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => S3fileSelectComponent),
      multi: true,
    },
  ],
})
export class S3fileSelectComponent implements OnInit {
  s3fileID: string;
  s3files: any[];
  propagateChange = (_: any) => {};

  constructor(private api: APIService) {}

  ngOnInit(): void {
    this.api.ListS3Files().then((list: ListS3FilesQuery) => {
      this.s3files = list.items;
      console.log(this.s3files);
      console.log(this.s3fileID);
    });
  }

  writeValue(value: any) {
    this.s3fileID = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_: any): void {}

  change(value: any) {
    this.propagateChange(value);
  }
}
