<mat-nav-list>
    <a *ngFor="let client of clients" mat-list-item [routerLink]="['/client', client.id]">
    <h3 matLine><span *ngIf="client.internalId"><b>{{client.internalId}}</b> </span></h3>
    <p matLine>Name - <em>{{client.name}}</em></p>
     <p matLine>Street - <em>{{client.street}}</em></p>
     <p matLine>City - <em>{{client.city}}</em></p>
     <p matLine>country - <em>{{client.country}}</em></p>
     <p matLine>Created - <em>{{client.createdAt | date:'medium'}}</em> </p>
     <p matLine>Updated - <em>{{client.updatedAt | date:'medium'}}</em> </p>
    </a>
</mat-nav-list>

<button mat-fab class="mat-fab-bottom-right" color="accent" [routerLink]="['/newclient']">
    <mat-icon>add</mat-icon>
</button>


