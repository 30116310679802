<mat-nav-list>
    <a *ngFor="let projectAssetBudget of projectAssetBudgets" mat-list-item [routerLink]="['/project-asset-budget', projectAssetBudget.id]">
        <h3 matLine><span *ngIf="projectAssetBudget.internalId">{{projectAssetBudget.internalId}} </span> </h3>

        <p matLine>Project Module - <b>{{projectAssetBudget.ProjectAsset?.name}}</b></p>
        <p matLine>Project Module Type - <em>{{projectAssetBudget.ProjectAsset?.type}}</em></p>
        <p matLine>Project Module Budget Hours - <em>{{projectAssetBudget.budgetHours}}</em> </p>
        <p matLine>Project Module Budget Amount - <em>{{projectAssetBudget.budgetAmount}}</em> </p>
        <p matLine>Project Module Tax Credit Percent - <em>{{projectAssetBudget.taxCreditPercent}}</em> </p>
        <p matLine>Project Module Tax Credit Hours - <em>{{projectAssetBudget.taxCreditHours}}</em> </p>
        <p matLine>Project Module Tax Credit Amount - <em>{{projectAssetBudget.taxCreditAmount}}</em> </p>
        <p matLine>Project Module Total Tax Credit - <em>{{projectAssetBudget.totalTaxCredit}}</em> </p>
        <p matLine>Client - <em>{{projectAssetBudget.ProjectAsset?.Project?.Client?.name}}</em></p>
        <p matLine>Created - <em>{{projectAssetBudget.createdAt | date:'medium'}}</em> </p>
        <p matLine>Updated - <em>{{projectAssetBudget.updatedAt | date:'medium'}}</em> </p>   
    </a>
</mat-nav-list>

<button mat-fab class="mat-fab-bottom-right" color="accent" [routerLink]="['/newproject-asset-budget']">
    <mat-icon>add</mat-icon>
</button>

