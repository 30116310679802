import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import {
  APIService,
  GetClientQuery,
  CreateClientMutation,
} from 'src/app/API.service';
import { NoticebarService } from '../services/noticebar.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css'],
})
export class ClientComponent implements OnInit {
  editMode: boolean;
  form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: APIService,
    private noticeBar: NoticebarService
  ) {}

  ngOnInit(): void {
    const now = new Date();
    const iid = 'C-' + formatDate(now, 'yyyy-MM-dd-HH-mm-ss-SSS', 'en-US');

    this.form = new FormGroup({
      id: new FormControl(null),
      internalId: new FormControl(iid, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      street: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      zip: new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.required]),
      //allowedGroups: new FormControl(null)
    });

    this.route.params.subscribe((params) => {
      this.editMode = 'id' in params;
      if (this.editMode) {
        this.loadClient(params['id']);
      }
    });
    Auth.currentAuthenticatedUser()
      .then((user) => console.log(user))
      .catch((err) => console.log(err));
  }

  loadClient(id: string) {
    this.api.GetClient(id).then((client: GetClientQuery) => {
      this.form.patchValue({
        id: client.id,
        internalId: client.internalId,
        name: client.name,
        street: client.street,
        city: client.city,
        zip: client.zip,
        country: client.country,
        description: client.description,
      });
    });
  }

  add() {
    const { id, internalId, name, street, city, zip, country, description } =
      this.form.value;
    if (this.form.status === 'VALID') {
      console.log(this.form.value);
      const clientData = {
        ...this.form.value,
        ...{
          editGroup: 'irc41-' + name + '-edit',
          viewGroup: 'irc41-' + name + '-view',
        },
      };
      this.api.CreateClient(clientData).then(() => {
        this.router.navigate(['clients']);
      });
    }
  }

  update() {
    if (this.form.status === 'VALID') {
      this.api
        .UpdateClient(this.form.value)
        .then(() => {
          this.router.navigate(['clients']);
        })
        .catch((err) => this.noticeBar.openNoticeBar(err));
    }
  }

  delete() {
    this.api
      .DeleteClient({ id: this.form.value['id'] })
      .then(() => {
        this.router.navigate(['clients']);
      })
      .catch((err) => this.noticeBar.openNoticeBar(err));
  }
}
