<h2 *ngIf="editMode">Edit Tax Credit Note</h2>
<h2 *ngIf="!editMode">Add a Tax Credit Note</h2>

<form [formGroup]="form">
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>InternalId</span></mat-label>
            <input matInput type="string" formControlName="internalId">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [style.width.px]="384">
            <mat-label><span>IRC41Category</span></mat-label>
            <mat-select formControlName="irc41Category">
              <mat-option value="NewProductProcess">New or Improved Process/Product</mat-option>
              <mat-option value="HardScience">Reliance on Hard Science or Technology</mat-option>
              <mat-option value="EliminationOfUncertainty">Technical Uncertainty</mat-option>
              <mat-option value="ProcessOfExperimentation">Process Of Experimentation</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [style.width.px]="1024">
            <mat-label><span>Note</span></mat-label>
            <textarea matInput formControlName="note" rows="16"></textarea>
            <mat-error *ngIf="form.get('note').hasError('required')">Note is required</mat-error>

        </mat-form-field>
    </div>

    <div>
        <app-project-asset-select formControlName="projectassetID"></app-project-asset-select>
    </div>

    <div class="buttonbar">
        <button *ngIf="!editMode" mat-raised-button color="primary" (click)="add()">Add</button>
        <button *ngIf="editMode" mat-raised-button color="warn" (click)="delete()">Delete</button>
        <button *ngIf="editMode" mat-raised-button color="primary" (click)="update()">Update</button>
    </div>
</form>


