import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  APIService,
  ListProjectsQuery,
  ListS3FilesQuery,
  S3File,
} from 'src/app/API.service';
import { Router } from '@angular/router';
import { from, Observable, of, Subscription } from 'rxjs';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { S3Service } from '../services/s3.service';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-s3files',
  templateUrl: './s3files.component.html',
  styleUrls: ['./s3files.component.css'],
})
export class S3filesComponent implements OnInit {
  s3files: any[];
  subscription: Subscription;

  constructor(
    private router: Router,
    private api: APIService,
    private s3Service: S3Service
  ) {}

  ngOnInit(): void {
    console.log('In ngOnInit');
    this.listS3Files();
  }
  listS3Files() {
    this.api.ListS3Files().then((list: ListS3FilesQuery) => {
      console.log(list);
      if (list.items.length == 0) {
        this.router.navigate(['news3file']);
        return;
      }
      this.s3files = list.items.sort(this.byCreatedAt);
    });
  }

  getClientImageUrl(key: string, indentityId: string) {
    console.log('In getClientImageUrl ', key, indentityId);
    return from(this.s3Service.getImageURL(key, indentityId)).pipe(
      tap((data) => {
        console.log(data);
      }),
      map((url) => {
        return `${url}`;
      })
    );
    //return of('something');
    //   .then((url) => {
    //     console.log('response from getClientImageUrl', url);
    //     return url;
    //   })
    //   .catch((err) => {
    //     console.log('error gettting URL', err);
    //     return 'error gettting URL';
    //   })
    //   .finally(() => {
    //     console.log('In finally');
    //     return 'none';
    //   });
    // return response;
  }

  // sort by createdAt, newest first
  byCreatedAt(a, b) {
    return a.createdAt < b.createdAt ? 1 : -1;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
