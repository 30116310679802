<h2 *ngIf="editMode">Edit Tax Credit Usage</h2>
<h2 *ngIf="!editMode">Add a Tax Credit Usage</h2>

<form [formGroup]="form">
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>InternalId</span></mat-label>
            <input matInput type="string" formControlName="internalId">
        </mat-form-field>
    </div>

    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>Usage Type</span></mat-label>
            <mat-select formControlName="type">
              <mat-option value="IncomeTaxOffset">Income Tax Offset</mat-option>
              <mat-option value="PayrollTaxOffset">Payroll Tax Offset</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>Amount</span></mat-label>
            <input matInput type="string" formControlName="amount">
            <mat-error *ngIf="form.get('amount').hasError('required')"> Amount required</mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>comment</span></mat-label>
            <input matInput type="string" formControlName="comment">
            <mat-error *ngIf="form.get('comment').hasError('required')">Comment is required</mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>TaxYear</span></mat-label>
            <input matInput type="string" formControlName="taxYear">
            <mat-error *ngIf="form.get('taxYear').hasError('required')">TaxYear is required</mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>TaxState</span></mat-label>
            <input matInput type="string" formControlName="taxState">
            <mat-error *ngIf="form.get('taxState').hasError('required')">Tax State is required</mat-error>

        </mat-form-field>
    </div>

    <div>
        <app-project-select formControlName="projectID"></app-project-select>
    </div>

    <div class="buttonbar">
        <button *ngIf="!editMode" mat-raised-button color="primary" (click)="add()">Add</button>
        <button *ngIf="editMode" mat-raised-button color="warn" (click)="delete()">Delete</button>
        <button *ngIf="editMode" mat-raised-button color="primary" (click)="update()">Update</button>
    </div>
</form>
