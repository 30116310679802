import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { APIService, ListProjectsQuery } from 'src/app/API.service';

@Component({
  selector: 'app-project-select',
  templateUrl: './project-select.component.html',
  styleUrls: ['./project-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProjectSelectComponent),
      multi: true,
    },
  ],
})
export class ProjectSelectComponent implements OnInit {
  projectID: string;

  projects: any[];

  propagateChange = (_: any) => {};

  // propagateChange = (_: any) => {
  //   return _;
  // };

  constructor(private api: APIService) {}

  ngOnInit(): void {
    this.api.ListProjects().then((list: ListProjectsQuery) => {
      this.projects = list.items;
    });
  }

  writeValue(value: any) {
    this.projectID = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_: any): void {}

  change(value: any) {
    this.propagateChange(value);
  }
}
