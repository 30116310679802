<h2 *ngIf="editMode">Edit s3file</h2>
<h2 *ngIf="!editMode">Add a s3file</h2>

<form [formGroup]="form">
  <div>
    <mat-form-field [style.width.px]="256">
      <mat-label><span>InternalId</span></mat-label>
      <input matInput type="string" formControlName="internalId" readonly />
      <mat-error *ngIf="form.get('internalId').hasError('required')"
        >You must enter a valid name</mat-error
      >
    </mat-form-field>
  </div>

  <div class="s3Image" *ngIf="s3ImageUrl != null">
    <img [src]="s3ImageUrl" alt="No Image Found" />
  </div>

  <div>
    <mat-label>
      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        class="file-input"
        (change)="onFileSelected($event)"
        #fileUpload
      />

      <div class="file-upload">
        {{ selectedFile ? selectedFile.name : "New file not uploaded yet." }}

        <button
          mat-mini-fab
          color="primary"
          class="upload-btn"
          (click)="fileUpload.click()"
        >
          <mat-icon>attach_file</mat-icon>
        </button>
      </div>
    </mat-label>
  </div>

  <div>
    <app-client-select formControlName="clientID"></app-client-select>
  </div>

  <div class="buttonbar">
    <button *ngIf="!editMode" mat-raised-button color="primary" (click)="add()">
      Add
    </button>
    <button *ngIf="editMode" mat-raised-button color="warn" (click)="delete()">
      Delete
    </button>
    <button
      *ngIf="editMode"
      mat-raised-button
      color="primary"
      (click)="update()"
    >
      Update
    </button>
  </div>
</form>
