import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { APIService, ListProjectTaxCreditUsagesQuery, OnUpdateProjectTaxCreditUsageSubscription, ProjectTaxCreditUsage } from 'src/app/API.service';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-project-tax-credit-usages',
  templateUrl: './project-tax-credit-usages.component.html',
  styleUrls: ['./project-tax-credit-usages.component.css']
})
export class ProjectTaxCreditUsagesComponent implements OnInit, OnDestroy, AfterViewInit {

  projectTaxCreditUsages: ProjectTaxCreditUsage[];
  subscription: Subscription;
  displayedColumns =  ["internalId", "clientName", "projectName", "type", "amount", "taxYear", "taxState", "comment", "updated"];
  dataSource: MatTableDataSource<ProjectTaxCreditUsage>; 
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private router: Router,
    private api: APIService) { }

  ngOnInit(): void {
    //this.listenChanges();
    //this.listProjectTaxCreditUsages();
  }

  ngAfterViewInit() {
    this.listProjectTaxCreditUsages();
  }

  listProjectTaxCreditUsages() {
    this.api.ListProjectTaxCreditUsages().then((list: ListProjectTaxCreditUsagesQuery) => {
      if (list.items.length == 0) {
        this.router.navigate(['newproject-tax-credit-usage']);
        return;
      }
      this.projectTaxCreditUsages = list.items.sort(this.byCreatedAt).map(item=>{
        return { ...item,...{projectName:item.Project.name, 
          clientName:item.Project.Client.name}}

      });
      console.log(this.projectTaxCreditUsages)
      this.dataSource = new MatTableDataSource(this.projectTaxCreditUsages);
      this.dataSource.sort = this.sort;
    });
  }
/*
  // listen changes on taxCreditUsages
  listenChanges() {
    Auth.currentAuthenticatedUser().then(user => {
      const OnUpdateProjectTaxCreditUsageListener: Observable<any> = API.graphql(
        graphqlOperation(
          `subscription OnUpdateProjectTaxCreditUsage($owner: String!) {
            onUpdateProjectTaxCreditUsage(owner: $owner) {
              __typename
              id
              serialnum
              title
              customerID
              customer {
                __typename
                id
                name
                address
                siret
                taxCreditUsages {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              lines {
                __typename
                items {
                  __typename
                  id
                  taxCreditUsageID
                  title
                  quantity
                  cost
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              owner
              updatedAt
            }
          }`, { owner: user.username }
        )
      ) as any;
      this.subscription = OnUpdateProjectTaxCreditUsageListener.subscribe(() => {
        this.listProjectTaxCreditUsages();
      });
    });
  }
  */

  // sort by createdAt, newest first
  byCreatedAt(a, b) {
    return a.createdAt < b.createdAt ? 1 : -1;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
