export class CustomError extends Error {
    date: Date;
    constructor(name:CustomErrorNames  = CustomErrorNames.Default, ...params) {
      // Pass remaining arguments (including vendor specific ones) to parent constructor
      super(...params)
  
      // Maintains proper stack trace for where our error was thrown (only available on V8)
      if (Error.captureStackTrace) {
        Error.captureStackTrace(this, CustomError)
      }
      this.name = name;
      // Custom debugging information
      this.date = new Date();
    }
  }

  export enum CustomErrorNames {
    CannotDeleteParent = "Cannot delete record before deleting its dependencies",
    InvlaidClient = "InvlaidClient",
    InvalidCreditType = "InvalidCreditType",
    Default = "Default"
  }