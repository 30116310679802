<form class="file-upload-form">
  <mat-label>
    <input
      type="file"
      accept=".jpg,.jpeg,.png"
      class="file-input"
      (change)="onFileSelected($event)"
      #fileUpload
    />

    <div class="file-upload">
      {{ selectedFile ? selectedFile.name : "No file uploaded yet." }}

      <button
        mat-mini-fab
        color="primary"
        class="upload-btn"
        (click)="fileUpload.click()"
      >
        <mat-icon>attach_file</mat-icon>
      </button>
    </div>
  </mat-label>
  <div>
    <app-client-select [formControl]="clientID"></app-client-select>
  </div>
  <button mat-raised-button color="accent" (click)="onSubmit()">Submit</button>
</form>
