import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService, GetProjectQuery, Project } from 'src/app/API.service';
import { CustomError, CustomErrorNames } from 'src/common/CustomErrors';
import { NoticebarService } from '../services/noticebar.service';
import { S3Service } from '../services/s3.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
})
export class ProjectComponent implements OnInit {
  editMode: boolean;
  form: FormGroup;
  project: Project;
  imageURL: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: APIService,
    private noticeBar: NoticebarService
  ) //private s3service: S3Service
  {}

  ngOnInit(): void {
    const now = new Date();
    const iid = 'P-' + formatDate(now, 'yyyy-MM-dd-HH-mm-ss-SSS', 'en-US');

    this.form = new FormGroup({
      id: new FormControl(null),
      clientID: new FormControl(iid),
      internalId: new FormControl(iid, [Validators.required]),
      contactName: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(''),
      s3FileImageID: new FormControl(null),
    });

    this.route.params.subscribe((params) => {
      this.editMode = 'id' in params;
      if (this.editMode) {
        this.loadProject(params['id']);
      }
    });
  }

  loadProject(id: string) {
    this.api.GetProject(id).then((project: GetProjectQuery) => {
      this.project = project;
      this.form.patchValue({
        id: project.id,
        clientID: project.clientID,
        internalId: project.internalId,
        contactName: project.contactName,
        name: project.name,
        s3FileImageID: project.s3FileImageID,
        description: project.description,
      });
    });
  }

  async getClientName(id: string) {
    return this.api.GetClient(id).then((client) => client.name);
  }

  async add() {
    if (this.form.status === 'VALID') {
      const clientName = await this.getClientName(this.form.value.clientID);
      console.log(this.form.value);
      const projectData = {
        ...this.form.value,
        ...{
          editGroup: 'irc41-' + clientName + '-edit',
          viewGroup: 'irc41-' + clientName + '-view',
        },
      };

      await this.api.CreateProject(projectData).then(() => {
        this.router.navigate(['projects']);
      });
    }
  }

  update() {
    if (this.form.status === 'VALID') {
      this.api
        .UpdateProject(this.form.value)
        .then(() => {
          this.router.navigate(['projects']);
        })
        .catch((err) => this.noticeBar.openNoticeBar(err));
    }
  }

  delete() {
    console.log(this.project);
    if (
      this.project.ProjectAssets?.items.length === 0 &&
      this.project.ProjectBudget !== undefined
    ) {
      this.api
        .DeleteProject({ id: this.form.value['id'] })
        .then(() => {
          this.router.navigate(['projects']);
        })
        .catch((err) => this.noticeBar.openNoticeBar(err));
    } else {
      this.noticeBar.openNoticeBar(
        new CustomError(CustomErrorNames.CannotDeleteParent)
      );
    }
  }
}
