import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService, GetTaxCreditNoteQuery } from 'src/app/API.service';
import { NoticebarService } from '../services/noticebar.service';

@Component({
  selector: 'app-tax-credit-note',
  templateUrl: './tax-credit-note.component.html',
  styleUrls: ['./tax-credit-note.component.css']
})
export class TaxCreditNoteComponent implements OnInit {

  editMode: boolean;
  form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: APIService,
    private noticeBar: NoticebarService) { }


  ngOnInit(): void {
    const now = new Date(); 
    const iid = "TCN-" + formatDate(now, "yyyy-MM-dd-HH-mm-ss-SSS", "en-US");

    this.form = new FormGroup({
      id: new FormControl(null),
      internalId: new FormControl(iid, [Validators.required]),
      irc41Category: new FormControl(null),
      note: new FormControl(null, [Validators.required]),
      projectassetID: new FormControl(null)
    });

    this.route.params.subscribe(params => {
      this.editMode = 'id' in params;
      if (this.editMode) {
        this.loadTaxCreditNote(params['id']);
      }
    });
  }

  loadTaxCreditNote(id: string) {
    this.api.GetTaxCreditNote(id).then((taxCreditNote: GetTaxCreditNoteQuery) => {
      this.form.patchValue({
        id: taxCreditNote.id,
        internalId: taxCreditNote.internalId,
        irc41Category: taxCreditNote.irc41Category,
        note: taxCreditNote.note,
        projectassetID: taxCreditNote.projectassetID,
      })
    });
  }


  // async getClientName(id: string) {
  //   return this.api.GetClient(id).then(client => client.name)
  // }

  // async add() {
  //   if (this.form.status === 'VALID') {
  //     const clientName = await this.getClientName(this.form.value.clientID);
  //     console.log(this.form.value);
  //     const projectData = {
  //       ...this.form.value,
  //       ...{ editGroup : "irc41-" + clientName + "-edit" , viewGroup : "irc41-" + clientName + "-view"}
  //     }
  async getClientNameByProjectAssetId(id: string) {
    console.log(this.api.GetProjectAsset(id));
    return this.api.GetProjectAsset(id)
      .then(projectAsset => projectAsset.Project.Client.name)
      .then( clientName => {console.log(clientName); return clientName;})
  }

  async add() {
    if (this.form.status === 'VALID') {
      const clientName = await this.getClientNameByProjectAssetId(this.form.value.projectassetID);
      console.log(this.form.value);
      const projectAssetData = {
        ...this.form.value,
        ...{ editGroup : "irc41-" + clientName + "-edit" , viewGroup : "irc41-" + clientName + "-view"}
      }      // handle the form data here. ex: send data to server etc.

      this.api.CreateTaxCreditNote(this.form.value).then(() => {
        this.router.navigate(['tax-credit-notes']);
      })
    }
  }

  update() {
    if (this.form.status === 'VALID') {
      this.api.UpdateTaxCreditNote(this.form.value).then(() => {
        this.router.navigate(['tax-credit-notes']);
      })
      .catch(err => this.noticeBar.openNoticeBar(err))
    }
  }

  delete() {
    this.api.DeleteTaxCreditNote({ id: this.form.value['id'] }).then(() => {
      this.router.navigate(['tax-credit-notes']);
    })
    .catch(err => this.noticeBar.openNoticeBar(err))
  }
}
