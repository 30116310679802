import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { APIService, ListProjectAssetsQuery } from 'src/app/API.service';

@Component({
  selector: 'app-project-asset-select',
  templateUrl: './project-asset-select.component.html',
  styleUrls: ['./project-asset-select.component.css'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProjectAssetSelectComponent),
      multi: true
    }
  ]
})
export class ProjectAssetSelectComponent implements OnInit {

  projectAssetID: string;

  projectAssets: any[];

  propagateChange = (_: any) => { };

  constructor(private api: APIService) { }

  ngOnInit(): void {
    this.api.ListProjectAssets().then((list: ListProjectAssetsQuery) => {
      this.projectAssets = list.items;
    });
  }

  writeValue(value: any) {
    this.projectAssetID = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_: any): void {}

  change(value: any) {
    this.propagateChange(value);
  }

}
