import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService, GetProjectAssetQuery, ProjectAsset } from 'src/app/API.service';
import { NoticebarService } from '../services/noticebar.service';
import { CustomError, CustomErrorNames } from '../../common/CustomErrors'

@Component({
  selector: 'app-project-asset',
  templateUrl: './project-asset.component.html',
  styleUrls: ['./project-asset.component.css']
})
export class ProjectAssetComponent implements OnInit {

  editMode: boolean;
  form: FormGroup;
  projectAsset: ProjectAsset;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private api: APIService,
    private noticeBar: NoticebarService) { }

  ngOnInit(): void {
    const now = new Date(); 
    const iid = "PA-" + formatDate(now, "yyyy-MM-dd-HH-mm-ss-SSS", "en-US");

    this.form = new FormGroup({
      id: new FormControl(null),
      internalId: new FormControl(iid, [Validators.required]),
      type: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      projectID: new FormControl(null)
    });

    this.route.params.subscribe(params => {
      this.editMode = 'id' in params;
      if (this.editMode) {
        this.loadProjectAsset(params['id']);
      }
    });
  }

  loadProjectAsset(id: string) {
    this.api.GetProjectAsset(id).then((projectAsset: GetProjectAssetQuery) => {
      this.projectAsset = projectAsset;
      this.form.patchValue({
        id: projectAsset.id,
        internalId: projectAsset.internalId,
        type: projectAsset.type,
        name: projectAsset.name,
        projectID: projectAsset.projectID,
      })
    });
  }


  async getClientNameByProjectId(id: string) {
    return this.api.GetProject(id)
      .then(project => project.Client.name)
      .then( clientName => {console.log(clientName); return clientName;})
  }

  async add() {
    if (this.form.status === 'VALID') {
      const clientName = await this.getClientNameByProjectId(this.form.value.projectID);
      console.log(this.form.value);
      const projectData = {
        ...this.form.value,
        ...{ editGroup : "irc41-" + clientName + "-edit" , viewGroup : "irc41-" + clientName + "-view"}
      }      // handle the form data here. ex: send data to server etc.
      this.api.CreateProjectAsset(this.form.value).then(() => {
        this.router.navigate(['project-assets']);
      })
    }
  }

  update() {
    if (this.form.status === 'VALID') {
      this.api.UpdateProjectAsset(this.form.value).then(() => {
        this.router.navigate(['project-assets']);
      })
      .catch(err => this.noticeBar.openNoticeBar(err))
    }  
  }

  delete() {
    console.log(this.projectAsset);
    if (this.projectAsset?.TaxCreditUsages.items.length === 0 
        && this.projectAsset.TaxCreditNotes.items.length === 0
        && this.projectAsset.ProjectAssetBudget !== undefined) {
      this.api.DeleteProjectAsset({ id: this.form.value['id'] }).then(() => {
        this.router.navigate(['project-assets']);
      })
      .catch(err => this.noticeBar.openNoticeBar(err))
    } else {
      this.noticeBar.openNoticeBar(new CustomError(CustomErrorNames.CannotDeleteParent));
    }
  }
}