<!-- <mat-nav-list>
    <a *ngFor="let clientNote of clientNotes" mat-list-item [routerLink]="['/client-note', clientNote.id]">
        <h3 matLine><span *ngIf="clientNote.internalId">{{clientNote.internalId}} </span> </h3>

        <p matLine>Client - <b>{{clientNote.Client?.name}}</b></p>
        <p matLine>To Do - <em>{{clientNote.note}}</em> </p>
        <p matLine>Created - <em>{{clientNote.createdAt | date:'medium'}}</em> </p>
        <p matLine>Updated - <em>{{clientNote.updatedAt | date:'medium'}}</em> </p>   
    </a>
</mat-nav-list> -->


    <mat-card class="client-notes" *ngFor="let clientNote of clientNotes" mat-list-item [routerLink]="['/client-note', clientNote.id]">
        <h3 ><span *ngIf="clientNote.internalId">{{clientNote.internalId}} </span> </h3>

        <p >Client - <b>{{clientNote.Client?.name}}</b></p>
        <mat-card><p >To Do - <em>{{clientNote.note}}</em> </p></mat-card>
        <p >Created - <em>{{clientNote.createdAt | date:'medium'}}</em> </p>
        <p >Updated - <em>{{clientNote.updatedAt | date:'medium'}}</em> </p>   
    </mat-card>

<button mat-fab class="mat-fab-bottom-right" color="accent" [routerLink]="['/newclient-note']">
    <mat-icon>add</mat-icon>
</button>
