import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { APIService, ListProjectAssetsQuery, OnUpdateProjectAssetSubscription, ProjectAsset } from 'src/app/API.service';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-project-assets',
  templateUrl: './project-assets.component.html',
  styleUrls: ['./project-assets.component.css']
})
export class ProjectAssetsComponent implements OnInit, AfterViewInit {


  projectAssets: ProjectAsset[];
  subscription: Subscription;
  displayedColumns =  ["internalId", "name", "type", "projectName", "clientName", "created", "updated"];
  dataSource: MatTableDataSource<ProjectAsset>; 
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private api: APIService) { }

  ngOnInit(): void {
    //this.listenChanges();
    //this.listProjectAssets();
  }

  ngAfterViewInit() {
    this.listProjectAssets();
  }

  listProjectAssets() {
    this.api.ListProjectAssets().then((list: ListProjectAssetsQuery) => {
      if (list.items.length == 0) {
        this.router.navigate(['newproject-asset']);
        return;
      }
      this.projectAssets = list.items.sort(this.byCreatedAt).map(item=>{
        return { ...item,...{projectName:item.Project.name, 
          clientName:item.Project.Client.name}}
      });

      console.log(this.projectAssets)
      this.dataSource = new MatTableDataSource(this.projectAssets);
      this.dataSource.sort = this.sort;
    });
  }

  // listen changes on bills
  listenChanges() {
    Auth.currentAuthenticatedUser().then(user => {
      const OnUpdateProjectAssetListener: Observable<any> = API.graphql(
        graphqlOperation(
          `subscription OnUpdateProjectAsset($owner: String!) {
            onUpdateProjectAsset(owner: $owner) {

  __typename
  id
  internalId
  type
  name
  projectID
  createdAt
  updatedAt
  TaxCreditNotes {
    __typename
    items {
      __typename
      id
      internalId
      irc41Category
      note
      projectassetID
      createdAt
      updatedAt
    } 
    nextToken
  } 
  ProjectAssetBudget {
    __typename
    id
    internalId
    budgetHours
    budgetAmount
    taxCreditPercent
    taxCreditHours
    taxCreditAmount
    totalTaxCredit
    createdAt
    updatedAt
    ProjectAsset {
      __typename
      id
      internalId
      type
      name
      projectID
      createdAt
      updatedAt
    } 
  } 
  Project {
    __typename
    id
    internalId
    name
    contactName
    clientID
    createdAt
    updatedAt
    ProjectAssets {
      __typename
      nextToken
    } 
    Client {
      __typename
      id
      internalId
      name
      street
      city
      zip
      country
      state
      createdAt
      updatedAt
    } 
  } 

            }
          }`, { owner: user.username }
        )
      ) as any;
      this.subscription = OnUpdateProjectAssetListener.subscribe(() => {
        this.listProjectAssets();
      });
    });
  }

  // sort by createdAt, newest first
  byCreatedAt(a, b) {
    return a.createdAt < b.createdAt ? 1 : -1;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
