import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { APIService, ListProjectBudgetsQuery, OnUpdateProjectBudgetSubscription, ProjectBudget } from 'src/app/API.service';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';


@Component({
  selector: 'app-project-budgets',
  templateUrl: './project-budgets.component.html',
  styleUrls: ['./project-budgets.component.css']
})
export class ProjectBudgetsComponent implements OnInit, AfterViewInit {

  projectBudgets: ProjectBudget[];
  subscription: Subscription;
  displayedColumns =  ["internalId", "clientName", "projectName", "budgetHours", "budgetAmount", "taxCreditHours", "taxCreditAmount", "updated"];
  dataSource: MatTableDataSource<ProjectBudget>; 
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private api: APIService) { }

  ngOnInit(): void {
    //this.listenChanges();
    //this.listProjectBudgets();
  }
  ngAfterViewInit() {
    this.listProjectBudgets();
  }

  listProjectBudgets() {
    this.api.ListProjectBudgets().then((list: ListProjectBudgetsQuery) => {
      if (list.items.length == 0) {
        this.router.navigate(['newproject-budget']);
        return;
      }
      this.projectBudgets = list.items.sort(this.byCreatedAt).map(item=>{
        return { ...item,...{projectName:item.Project.name, 
          clientName:item.Project.Client.name}}

      });
      console.log(this.projectBudgets)
      this.dataSource = new MatTableDataSource(this.projectBudgets);
      this.dataSource.sort = this.sort;
    });
  }

  // listen changes on projectBudgets
  listenChanges() {
    Auth.currentAuthenticatedUser().then(user => {
      const OnUpdateProjectBudgetListener: Observable<any> = API.graphql(
        graphqlOperation(
          `subscription OnUpdateProjectBudget($owner: String!) {
            onUpdateProjectBudget(owner: $owner) {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              customerID
              Project {
                __typename
                id
                name
                address
                siret
                projectBudgets {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              lines {
                __typename
                items {
                  __typename
                  id
                  projectBudgetID
                  title
                  quantity
                  cost
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              owner
              updatedAt
            }
          }`, { owner: user.username }
        )
      ) as any;
      this.subscription = OnUpdateProjectBudgetListener.subscribe(() => {
        this.listProjectBudgets();
      });
    });
  }

  // sort by createdAt, newest first
  byCreatedAt(a, b) {
    return a.createdAt < b.createdAt ? 1 : -1;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
