<h2 *ngIf="editMode">Edit project</h2>
<h2 *ngIf="!editMode">Add a project</h2>

<form [formGroup]="form">
  <div>
    <mat-form-field [style.width.px]="256">
      <mat-label><span>InternalId</span></mat-label>
      <input matInput type="string" formControlName="internalId" readonly />
      <mat-error *ngIf="form.get('internalId').hasError('required')"
        >You must enter a valid name</mat-error
      >
    </mat-form-field>
  </div>
  <div>
    <mat-form-field [style.width.px]="256">
      <mat-label><span>Name</span></mat-label>
      <input matInput type="string" formControlName="name" />
      <mat-error *ngIf="form.get('name').hasError('required')"
        >Name is required</mat-error
      >
    </mat-form-field>
  </div>
  <div>
    <mat-form-field [style.width.px]="256">
      <mat-label><span>ContactName</span></mat-label>
      <input matInput type="string" formControlName="contactName" />
      <mat-error *ngIf="form.get('contactName').hasError('required')"
        >Contact Name is required</mat-error
      >
    </mat-form-field>
  </div>
  <!-- <div>
    <mat-form-field [style.width.px]="256">
      <mat-label><span>Description</span></mat-label>
      <input matInput type="string" formControlName="description" />
      <mat-error *ngIf="form.get('description').hasError('required')"
        >Description is required</mat-error
      >
    </mat-form-field>
  </div> -->
  <div>
    <mat-form-field [style.width.px]="1024">
      <mat-label><span>Description</span></mat-label>
      <textarea matInput formControlName="description" rows="12"></textarea>
      <mat-error *ngIf="form.get('description').hasError('required')">
        Description is required
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <app-client-select formControlName="clientID"></app-client-select>
  </div>

  <div>
    <app-s3file-select formControlName="s3FileImageID"></app-s3file-select>
  </div>

  <div class="buttonbar">
    <button *ngIf="!editMode" mat-raised-button color="primary" (click)="add()">
      Add
    </button>
    <button *ngIf="editMode" mat-raised-button color="warn" (click)="delete()">
      Delete
    </button>
    <button
      *ngIf="editMode"
      mat-raised-button
      color="primary"
      (click)="update()"
    >
      Update
    </button>
  </div>
</form>
