/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreateTaxCreditUsageInput = {
  id?: string | null;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type ModelTaxCreditUsageConditionInput = {
  internalId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  amount?: ModelFloatInput | null;
  comment?: ModelStringInput | null;
  taxYear?: ModelIntInput | null;
  taxState?: ModelStringInput | null;
  projectassetID?: ModelIDInput | null;
  and?: Array<ModelTaxCreditUsageConditionInput | null> | null;
  or?: Array<ModelTaxCreditUsageConditionInput | null> | null;
  not?: ModelTaxCreditUsageConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type TaxCreditUsage = {
  __typename: "TaxCreditUsage";
  id?: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt?: string;
  updatedAt?: string;
  owner?: string | null;
  ProjectAsset?: ProjectAsset;
};

export type ProjectAsset = {
  __typename: "ProjectAsset";
  id?: string;
  internalId?: string | null;
  type?: string | null;
  name?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt?: string;
  updatedAt?: string;
  TaxCreditUsages?: ModelTaxCreditUsageConnection;
  TaxCreditNotes?: ModelTaxCreditNoteConnection;
  ProjectAssetBudget?: ProjectAssetBudget;
  owner?: string | null;
  Project?: Project;
};

export type ModelTaxCreditUsageConnection = {
  __typename: "ModelTaxCreditUsageConnection";
  items?: Array<TaxCreditUsage | null> | null;
  nextToken?: string | null;
};

export type ModelTaxCreditNoteConnection = {
  __typename: "ModelTaxCreditNoteConnection";
  items?: Array<TaxCreditNote | null> | null;
  nextToken?: string | null;
};

export type TaxCreditNote = {
  __typename: "TaxCreditNote";
  id?: string;
  internalId?: string | null;
  irc41Category?: string | null;
  note?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt?: string;
  updatedAt?: string;
  owner?: string | null;
  ProjectAsset?: ProjectAsset;
};

export type ProjectAssetBudget = {
  __typename: "ProjectAssetBudget";
  id?: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt?: string;
  updatedAt?: string;
  owner?: string | null;
  ProjectAsset?: ProjectAsset;
};

export type Project = {
  __typename: "Project";
  id?: string;
  internalId?: string | null;
  name?: string | null;
  contactName?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt?: string;
  updatedAt?: string;
  ProjectAssets?: ModelProjectAssetConnection;
  ProjectBudget?: ProjectBudget;
  owner?: string | null;
  Client?: Client;
  s3fileImage?: S3File;
};

export type ModelProjectAssetConnection = {
  __typename: "ModelProjectAssetConnection";
  items?: Array<ProjectAsset | null> | null;
  nextToken?: string | null;
};

export type ProjectBudget = {
  __typename: "ProjectBudget";
  id?: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt?: string;
  updatedAt?: string;
  owner?: string | null;
  Project?: Project;
};

export type Client = {
  __typename: "Client";
  id?: string;
  internalId?: string | null;
  name?: string | null;
  street?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  state?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt?: string;
  updatedAt?: string;
  Projects?: ModelProjectConnection;
  owner?: string | null;
  s3fileImage?: S3File;
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection";
  items?: Array<Project | null> | null;
  nextToken?: string | null;
};

export type S3File = {
  __typename: "S3File";
  id?: string;
  internalId?: string | null;
  s3key?: string | null;
  s3IdentityId?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt?: string;
  updatedAt?: string;
  owner?: string | null;
};

export type UpdateTaxCreditUsageInput = {
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type DeleteTaxCreditUsageInput = {
  id?: string | null;
};

export type CreateTaxCreditNoteInput = {
  id?: string | null;
  internalId?: string | null;
  irc41Category?: string | null;
  note?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type ModelTaxCreditNoteConditionInput = {
  internalId?: ModelStringInput | null;
  irc41Category?: ModelStringInput | null;
  note?: ModelStringInput | null;
  projectassetID?: ModelIDInput | null;
  and?: Array<ModelTaxCreditNoteConditionInput | null> | null;
  or?: Array<ModelTaxCreditNoteConditionInput | null> | null;
  not?: ModelTaxCreditNoteConditionInput | null;
};

export type UpdateTaxCreditNoteInput = {
  id: string;
  internalId?: string | null;
  irc41Category?: string | null;
  note?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type DeleteTaxCreditNoteInput = {
  id?: string | null;
};

export type CreateProjectAssetBudgetInput = {
  id?: string | null;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type ModelProjectAssetBudgetConditionInput = {
  internalId?: ModelStringInput | null;
  budgetHours?: ModelFloatInput | null;
  budgetAmount?: ModelFloatInput | null;
  taxCreditPercent?: ModelFloatInput | null;
  taxCreditHours?: ModelFloatInput | null;
  taxCreditAmount?: ModelFloatInput | null;
  totalTaxCredit?: ModelFloatInput | null;
  projectassetID?: ModelIDInput | null;
  and?: Array<ModelProjectAssetBudgetConditionInput | null> | null;
  or?: Array<ModelProjectAssetBudgetConditionInput | null> | null;
  not?: ModelProjectAssetBudgetConditionInput | null;
};

export type UpdateProjectAssetBudgetInput = {
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type DeleteProjectAssetBudgetInput = {
  id?: string | null;
};

export type CreateProjectAssetInput = {
  id?: string | null;
  internalId?: string | null;
  type?: string | null;
  name?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  projectAssetProjectAssetBudgetId?: string | null;
};

export type ModelProjectAssetConditionInput = {
  internalId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  name?: ModelStringInput | null;
  projectID?: ModelIDInput | null;
  and?: Array<ModelProjectAssetConditionInput | null> | null;
  or?: Array<ModelProjectAssetConditionInput | null> | null;
  not?: ModelProjectAssetConditionInput | null;
};

export type UpdateProjectAssetInput = {
  id: string;
  internalId?: string | null;
  type?: string | null;
  name?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  projectAssetProjectAssetBudgetId?: string | null;
};

export type DeleteProjectAssetInput = {
  id?: string | null;
};

export type CreateProjectTaxCreditUsageInput = {
  id?: string | null;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type ModelProjectTaxCreditUsageConditionInput = {
  internalId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  amount?: ModelFloatInput | null;
  comment?: ModelStringInput | null;
  taxYear?: ModelIntInput | null;
  taxState?: ModelStringInput | null;
  projectID?: ModelIDInput | null;
  and?: Array<ModelProjectTaxCreditUsageConditionInput | null> | null;
  or?: Array<ModelProjectTaxCreditUsageConditionInput | null> | null;
  not?: ModelProjectTaxCreditUsageConditionInput | null;
};

export type ProjectTaxCreditUsage = {
  __typename: "ProjectTaxCreditUsage";
  id?: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt?: string;
  updatedAt?: string;
  owner?: string | null;
  Project?: Project;
};

export type UpdateProjectTaxCreditUsageInput = {
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type DeleteProjectTaxCreditUsageInput = {
  id?: string | null;
};

export type CreateProjectBudgetInput = {
  id?: string | null;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type ModelProjectBudgetConditionInput = {
  internalId?: ModelStringInput | null;
  budgetHours?: ModelFloatInput | null;
  budgetAmount?: ModelFloatInput | null;
  taxCreditPercent?: ModelFloatInput | null;
  taxCreditHours?: ModelFloatInput | null;
  taxCreditAmount?: ModelFloatInput | null;
  totalTaxCredit?: ModelFloatInput | null;
  projectID?: ModelIDInput | null;
  and?: Array<ModelProjectBudgetConditionInput | null> | null;
  or?: Array<ModelProjectBudgetConditionInput | null> | null;
  not?: ModelProjectBudgetConditionInput | null;
};

export type UpdateProjectBudgetInput = {
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type DeleteProjectBudgetInput = {
  id?: string | null;
};

export type CreateProjectInput = {
  id?: string | null;
  internalId?: string | null;
  name?: string | null;
  contactName?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  projectProjectBudgetId?: string | null;
};

export type ModelProjectConditionInput = {
  internalId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  contactName?: ModelStringInput | null;
  description?: ModelStringInput | null;
  s3FileImageID?: ModelIDInput | null;
  clientID?: ModelIDInput | null;
  and?: Array<ModelProjectConditionInput | null> | null;
  or?: Array<ModelProjectConditionInput | null> | null;
  not?: ModelProjectConditionInput | null;
};

export type UpdateProjectInput = {
  id: string;
  internalId?: string | null;
  name?: string | null;
  contactName?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  projectProjectBudgetId?: string | null;
};

export type DeleteProjectInput = {
  id?: string | null;
};

export type CreateClientNoteInput = {
  id?: string | null;
  internalId?: string | null;
  note?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type ModelClientNoteConditionInput = {
  internalId?: ModelStringInput | null;
  note?: ModelStringInput | null;
  clientID?: ModelIDInput | null;
  and?: Array<ModelClientNoteConditionInput | null> | null;
  or?: Array<ModelClientNoteConditionInput | null> | null;
  not?: ModelClientNoteConditionInput | null;
};

export type ClientNote = {
  __typename: "ClientNote";
  id?: string;
  internalId?: string | null;
  note?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt?: string;
  updatedAt?: string;
  owner?: string | null;
  Client?: Client;
};

export type UpdateClientNoteInput = {
  id: string;
  internalId?: string | null;
  note?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type DeleteClientNoteInput = {
  id?: string | null;
};

export type CreateClientInput = {
  id?: string | null;
  internalId?: string | null;
  name?: string | null;
  street?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  state?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type ModelClientConditionInput = {
  internalId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  street?: ModelStringInput | null;
  city?: ModelStringInput | null;
  zip?: ModelStringInput | null;
  country?: ModelStringInput | null;
  state?: ModelStringInput | null;
  description?: ModelStringInput | null;
  s3FileImageID?: ModelIDInput | null;
  and?: Array<ModelClientConditionInput | null> | null;
  or?: Array<ModelClientConditionInput | null> | null;
  not?: ModelClientConditionInput | null;
};

export type UpdateClientInput = {
  id: string;
  internalId?: string | null;
  name?: string | null;
  street?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  state?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type DeleteClientInput = {
  id?: string | null;
};

export type CreateS3FileInput = {
  id?: string | null;
  internalId?: string | null;
  s3key?: string | null;
  s3IdentityId?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type ModelS3FileConditionInput = {
  internalId?: ModelStringInput | null;
  s3key?: ModelStringInput | null;
  s3IdentityId?: ModelStringInput | null;
  clientID?: ModelIDInput | null;
  and?: Array<ModelS3FileConditionInput | null> | null;
  or?: Array<ModelS3FileConditionInput | null> | null;
  not?: ModelS3FileConditionInput | null;
};

export type UpdateS3FileInput = {
  id: string;
  internalId?: string | null;
  s3key?: string | null;
  s3IdentityId?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
};

export type DeleteS3FileInput = {
  id?: string | null;
};

export type ModelTaxCreditUsageFilterInput = {
  id?: ModelIDInput | null;
  internalId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  amount?: ModelFloatInput | null;
  comment?: ModelStringInput | null;
  taxYear?: ModelIntInput | null;
  taxState?: ModelStringInput | null;
  projectassetID?: ModelIDInput | null;
  editGroup?: ModelStringInput | null;
  viewGroup?: ModelStringInput | null;
  and?: Array<ModelTaxCreditUsageFilterInput | null> | null;
  or?: Array<ModelTaxCreditUsageFilterInput | null> | null;
  not?: ModelTaxCreditUsageFilterInput | null;
};

export type ModelTaxCreditNoteFilterInput = {
  id?: ModelIDInput | null;
  internalId?: ModelStringInput | null;
  irc41Category?: ModelStringInput | null;
  note?: ModelStringInput | null;
  projectassetID?: ModelIDInput | null;
  editGroup?: ModelStringInput | null;
  viewGroup?: ModelStringInput | null;
  and?: Array<ModelTaxCreditNoteFilterInput | null> | null;
  or?: Array<ModelTaxCreditNoteFilterInput | null> | null;
  not?: ModelTaxCreditNoteFilterInput | null;
};

export type ModelProjectAssetBudgetFilterInput = {
  id?: ModelIDInput | null;
  internalId?: ModelStringInput | null;
  budgetHours?: ModelFloatInput | null;
  budgetAmount?: ModelFloatInput | null;
  taxCreditPercent?: ModelFloatInput | null;
  taxCreditHours?: ModelFloatInput | null;
  taxCreditAmount?: ModelFloatInput | null;
  totalTaxCredit?: ModelFloatInput | null;
  projectassetID?: ModelIDInput | null;
  editGroup?: ModelStringInput | null;
  viewGroup?: ModelStringInput | null;
  and?: Array<ModelProjectAssetBudgetFilterInput | null> | null;
  or?: Array<ModelProjectAssetBudgetFilterInput | null> | null;
  not?: ModelProjectAssetBudgetFilterInput | null;
};

export type ModelProjectAssetBudgetConnection = {
  __typename: "ModelProjectAssetBudgetConnection";
  items?: Array<ProjectAssetBudget | null> | null;
  nextToken?: string | null;
};

export type ModelProjectAssetFilterInput = {
  id?: ModelIDInput | null;
  internalId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  name?: ModelStringInput | null;
  projectID?: ModelIDInput | null;
  editGroup?: ModelStringInput | null;
  viewGroup?: ModelStringInput | null;
  and?: Array<ModelProjectAssetFilterInput | null> | null;
  or?: Array<ModelProjectAssetFilterInput | null> | null;
  not?: ModelProjectAssetFilterInput | null;
};

export type ModelProjectTaxCreditUsageFilterInput = {
  id?: ModelIDInput | null;
  internalId?: ModelStringInput | null;
  type?: ModelStringInput | null;
  amount?: ModelFloatInput | null;
  comment?: ModelStringInput | null;
  taxYear?: ModelIntInput | null;
  taxState?: ModelStringInput | null;
  projectID?: ModelIDInput | null;
  editGroup?: ModelStringInput | null;
  viewGroup?: ModelStringInput | null;
  and?: Array<ModelProjectTaxCreditUsageFilterInput | null> | null;
  or?: Array<ModelProjectTaxCreditUsageFilterInput | null> | null;
  not?: ModelProjectTaxCreditUsageFilterInput | null;
};

export type ModelProjectTaxCreditUsageConnection = {
  __typename: "ModelProjectTaxCreditUsageConnection";
  items?: Array<ProjectTaxCreditUsage | null> | null;
  nextToken?: string | null;
};

export type ModelProjectBudgetFilterInput = {
  id?: ModelIDInput | null;
  internalId?: ModelStringInput | null;
  budgetHours?: ModelFloatInput | null;
  budgetAmount?: ModelFloatInput | null;
  taxCreditPercent?: ModelFloatInput | null;
  taxCreditHours?: ModelFloatInput | null;
  taxCreditAmount?: ModelFloatInput | null;
  totalTaxCredit?: ModelFloatInput | null;
  projectID?: ModelIDInput | null;
  editGroup?: ModelStringInput | null;
  viewGroup?: ModelStringInput | null;
  and?: Array<ModelProjectBudgetFilterInput | null> | null;
  or?: Array<ModelProjectBudgetFilterInput | null> | null;
  not?: ModelProjectBudgetFilterInput | null;
};

export type ModelProjectBudgetConnection = {
  __typename: "ModelProjectBudgetConnection";
  items?: Array<ProjectBudget | null> | null;
  nextToken?: string | null;
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null;
  internalId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  contactName?: ModelStringInput | null;
  description?: ModelStringInput | null;
  s3FileImageID?: ModelIDInput | null;
  clientID?: ModelIDInput | null;
  editGroup?: ModelStringInput | null;
  viewGroup?: ModelStringInput | null;
  and?: Array<ModelProjectFilterInput | null> | null;
  or?: Array<ModelProjectFilterInput | null> | null;
  not?: ModelProjectFilterInput | null;
};

export type ModelClientNoteFilterInput = {
  id?: ModelIDInput | null;
  internalId?: ModelStringInput | null;
  note?: ModelStringInput | null;
  clientID?: ModelIDInput | null;
  editGroup?: ModelStringInput | null;
  viewGroup?: ModelStringInput | null;
  and?: Array<ModelClientNoteFilterInput | null> | null;
  or?: Array<ModelClientNoteFilterInput | null> | null;
  not?: ModelClientNoteFilterInput | null;
};

export type ModelClientNoteConnection = {
  __typename: "ModelClientNoteConnection";
  items?: Array<ClientNote | null> | null;
  nextToken?: string | null;
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null;
  internalId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  street?: ModelStringInput | null;
  city?: ModelStringInput | null;
  zip?: ModelStringInput | null;
  country?: ModelStringInput | null;
  state?: ModelStringInput | null;
  description?: ModelStringInput | null;
  s3FileImageID?: ModelIDInput | null;
  editGroup?: ModelStringInput | null;
  viewGroup?: ModelStringInput | null;
  and?: Array<ModelClientFilterInput | null> | null;
  or?: Array<ModelClientFilterInput | null> | null;
  not?: ModelClientFilterInput | null;
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection";
  items?: Array<Client | null> | null;
  nextToken?: string | null;
};

export type ModelS3FileFilterInput = {
  id?: ModelIDInput | null;
  internalId?: ModelStringInput | null;
  s3key?: ModelStringInput | null;
  s3IdentityId?: ModelStringInput | null;
  clientID?: ModelIDInput | null;
  editGroup?: ModelStringInput | null;
  viewGroup?: ModelStringInput | null;
  and?: Array<ModelS3FileFilterInput | null> | null;
  or?: Array<ModelS3FileFilterInput | null> | null;
  not?: ModelS3FileFilterInput | null;
};

export type ModelS3FileConnection = {
  __typename: "ModelS3FileConnection";
  items?: Array<S3File | null> | null;
  nextToken?: string | null;
};

export type CreateTaxCreditUsageMutation = {
  __typename: "TaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateTaxCreditUsageMutation = {
  __typename: "TaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteTaxCreditUsageMutation = {
  __typename: "TaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateTaxCreditNoteMutation = {
  __typename: "TaxCreditNote";
  id: string;
  internalId?: string | null;
  irc41Category?: string | null;
  note?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateTaxCreditNoteMutation = {
  __typename: "TaxCreditNote";
  id: string;
  internalId?: string | null;
  irc41Category?: string | null;
  note?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteTaxCreditNoteMutation = {
  __typename: "TaxCreditNote";
  id: string;
  internalId?: string | null;
  irc41Category?: string | null;
  note?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateProjectAssetBudgetMutation = {
  __typename: "ProjectAssetBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateProjectAssetBudgetMutation = {
  __typename: "ProjectAssetBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteProjectAssetBudgetMutation = {
  __typename: "ProjectAssetBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CreateProjectAssetMutation = {
  __typename: "ProjectAsset";
  id: string;
  internalId?: string | null;
  type?: string | null;
  name?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  TaxCreditUsages?: {
    __typename: "ModelTaxCreditUsageConnection";
    items?: Array<{
      __typename: "TaxCreditUsage";
      id: string;
      internalId?: string | null;
      type?: string | null;
      amount?: number | null;
      comment?: string | null;
      taxYear?: number | null;
      taxState?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  TaxCreditNotes?: {
    __typename: "ModelTaxCreditNoteConnection";
    items?: Array<{
      __typename: "TaxCreditNote";
      id: string;
      internalId?: string | null;
      irc41Category?: string | null;
      note?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectAssetBudget?: {
    __typename: "ProjectAssetBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectassetID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    ProjectAsset?: {
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type UpdateProjectAssetMutation = {
  __typename: "ProjectAsset";
  id: string;
  internalId?: string | null;
  type?: string | null;
  name?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  TaxCreditUsages?: {
    __typename: "ModelTaxCreditUsageConnection";
    items?: Array<{
      __typename: "TaxCreditUsage";
      id: string;
      internalId?: string | null;
      type?: string | null;
      amount?: number | null;
      comment?: string | null;
      taxYear?: number | null;
      taxState?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  TaxCreditNotes?: {
    __typename: "ModelTaxCreditNoteConnection";
    items?: Array<{
      __typename: "TaxCreditNote";
      id: string;
      internalId?: string | null;
      irc41Category?: string | null;
      note?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectAssetBudget?: {
    __typename: "ProjectAssetBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectassetID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    ProjectAsset?: {
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type DeleteProjectAssetMutation = {
  __typename: "ProjectAsset";
  id: string;
  internalId?: string | null;
  type?: string | null;
  name?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  TaxCreditUsages?: {
    __typename: "ModelTaxCreditUsageConnection";
    items?: Array<{
      __typename: "TaxCreditUsage";
      id: string;
      internalId?: string | null;
      type?: string | null;
      amount?: number | null;
      comment?: string | null;
      taxYear?: number | null;
      taxState?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  TaxCreditNotes?: {
    __typename: "ModelTaxCreditNoteConnection";
    items?: Array<{
      __typename: "TaxCreditNote";
      id: string;
      internalId?: string | null;
      irc41Category?: string | null;
      note?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectAssetBudget?: {
    __typename: "ProjectAssetBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectassetID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    ProjectAsset?: {
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type CreateProjectTaxCreditUsageMutation = {
  __typename: "ProjectTaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type UpdateProjectTaxCreditUsageMutation = {
  __typename: "ProjectTaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type DeleteProjectTaxCreditUsageMutation = {
  __typename: "ProjectTaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type CreateProjectBudgetMutation = {
  __typename: "ProjectBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type UpdateProjectBudgetMutation = {
  __typename: "ProjectBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type DeleteProjectBudgetMutation = {
  __typename: "ProjectBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type CreateProjectMutation = {
  __typename: "Project";
  id: string;
  internalId?: string | null;
  name?: string | null;
  contactName?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  ProjectAssets?: {
    __typename: "ModelProjectAssetConnection";
    items?: Array<{
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectBudget?: {
    __typename: "ProjectBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type UpdateProjectMutation = {
  __typename: "Project";
  id: string;
  internalId?: string | null;
  name?: string | null;
  contactName?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  ProjectAssets?: {
    __typename: "ModelProjectAssetConnection";
    items?: Array<{
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectBudget?: {
    __typename: "ProjectBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type DeleteProjectMutation = {
  __typename: "Project";
  id: string;
  internalId?: string | null;
  name?: string | null;
  contactName?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  ProjectAssets?: {
    __typename: "ModelProjectAssetConnection";
    items?: Array<{
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectBudget?: {
    __typename: "ProjectBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type CreateClientNoteMutation = {
  __typename: "ClientNote";
  id: string;
  internalId?: string | null;
  note?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type UpdateClientNoteMutation = {
  __typename: "ClientNote";
  id: string;
  internalId?: string | null;
  note?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type DeleteClientNoteMutation = {
  __typename: "ClientNote";
  id: string;
  internalId?: string | null;
  note?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type CreateClientMutation = {
  __typename: "Client";
  id: string;
  internalId?: string | null;
  name?: string | null;
  street?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  state?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  Projects?: {
    __typename: "ModelProjectConnection";
    items?: Array<{
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type UpdateClientMutation = {
  __typename: "Client";
  id: string;
  internalId?: string | null;
  name?: string | null;
  street?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  state?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  Projects?: {
    __typename: "ModelProjectConnection";
    items?: Array<{
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type DeleteClientMutation = {
  __typename: "Client";
  id: string;
  internalId?: string | null;
  name?: string | null;
  street?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  state?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  Projects?: {
    __typename: "ModelProjectConnection";
    items?: Array<{
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type CreateS3FileMutation = {
  __typename: "S3File";
  id: string;
  internalId?: string | null;
  s3key?: string | null;
  s3IdentityId?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type UpdateS3FileMutation = {
  __typename: "S3File";
  id: string;
  internalId?: string | null;
  s3key?: string | null;
  s3IdentityId?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type DeleteS3FileMutation = {
  __typename: "S3File";
  id: string;
  internalId?: string | null;
  s3key?: string | null;
  s3IdentityId?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type GetTaxCreditUsageQuery = {
  __typename: "TaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListTaxCreditUsagesQuery = {
  __typename: "ModelTaxCreditUsageConnection";
  items?: Array<{
    __typename: "TaxCreditUsage";
    id: string;
    internalId?: string | null;
    type?: string | null;
    amount?: number | null;
    comment?: string | null;
    taxYear?: number | null;
    taxState?: string | null;
    projectassetID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    ProjectAsset?: {
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetTaxCreditNoteQuery = {
  __typename: "TaxCreditNote";
  id: string;
  internalId?: string | null;
  irc41Category?: string | null;
  note?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListTaxCreditNotesQuery = {
  __typename: "ModelTaxCreditNoteConnection";
  items?: Array<{
    __typename: "TaxCreditNote";
    id: string;
    internalId?: string | null;
    irc41Category?: string | null;
    note?: string | null;
    projectassetID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    ProjectAsset?: {
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListProjectAssetBudgetsQuery = {
  __typename: "ModelProjectAssetBudgetConnection";
  items?: Array<{
    __typename: "ProjectAssetBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectassetID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    ProjectAsset?: {
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetProjectAssetBudgetQuery = {
  __typename: "ProjectAssetBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetProjectAssetQuery = {
  __typename: "ProjectAsset";
  id: string;
  internalId?: string | null;
  type?: string | null;
  name?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  TaxCreditUsages?: {
    __typename: "ModelTaxCreditUsageConnection";
    items?: Array<{
      __typename: "TaxCreditUsage";
      id: string;
      internalId?: string | null;
      type?: string | null;
      amount?: number | null;
      comment?: string | null;
      taxYear?: number | null;
      taxState?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  TaxCreditNotes?: {
    __typename: "ModelTaxCreditNoteConnection";
    items?: Array<{
      __typename: "TaxCreditNote";
      id: string;
      internalId?: string | null;
      irc41Category?: string | null;
      note?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectAssetBudget?: {
    __typename: "ProjectAssetBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectassetID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    ProjectAsset?: {
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type ListProjectAssetsQuery = {
  __typename: "ModelProjectAssetConnection";
  items?: Array<{
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetProjectTaxCreditUsageQuery = {
  __typename: "ProjectTaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type ListProjectTaxCreditUsagesQuery = {
  __typename: "ModelProjectTaxCreditUsageConnection";
  items?: Array<{
    __typename: "ProjectTaxCreditUsage";
    id: string;
    internalId?: string | null;
    type?: string | null;
    amount?: number | null;
    comment?: string | null;
    taxYear?: number | null;
    taxState?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListProjectBudgetsQuery = {
  __typename: "ModelProjectBudgetConnection";
  items?: Array<{
    __typename: "ProjectBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetProjectBudgetQuery = {
  __typename: "ProjectBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type GetProjectQuery = {
  __typename: "Project";
  id: string;
  internalId?: string | null;
  name?: string | null;
  contactName?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  ProjectAssets?: {
    __typename: "ModelProjectAssetConnection";
    items?: Array<{
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectBudget?: {
    __typename: "ProjectBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type ListProjectsQuery = {
  __typename: "ModelProjectConnection";
  items?: Array<{
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetClientNoteQuery = {
  __typename: "ClientNote";
  id: string;
  internalId?: string | null;
  note?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type ListClientNotesQuery = {
  __typename: "ModelClientNoteConnection";
  items?: Array<{
    __typename: "ClientNote";
    id: string;
    internalId?: string | null;
    note?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListClientsQuery = {
  __typename: "ModelClientConnection";
  items?: Array<{
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetClientQuery = {
  __typename: "Client";
  id: string;
  internalId?: string | null;
  name?: string | null;
  street?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  state?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  Projects?: {
    __typename: "ModelProjectConnection";
    items?: Array<{
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type ListS3FilesQuery = {
  __typename: "ModelS3FileConnection";
  items?: Array<{
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetS3FileQuery = {
  __typename: "S3File";
  id: string;
  internalId?: string | null;
  s3key?: string | null;
  s3IdentityId?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type OnCreateTaxCreditUsageSubscription = {
  __typename: "TaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateTaxCreditUsageSubscription = {
  __typename: "TaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteTaxCreditUsageSubscription = {
  __typename: "TaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateTaxCreditNoteSubscription = {
  __typename: "TaxCreditNote";
  id: string;
  internalId?: string | null;
  irc41Category?: string | null;
  note?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateTaxCreditNoteSubscription = {
  __typename: "TaxCreditNote";
  id: string;
  internalId?: string | null;
  irc41Category?: string | null;
  note?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteTaxCreditNoteSubscription = {
  __typename: "TaxCreditNote";
  id: string;
  internalId?: string | null;
  irc41Category?: string | null;
  note?: string | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateProjectAssetBudgetSubscription = {
  __typename: "ProjectAssetBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateProjectAssetBudgetSubscription = {
  __typename: "ProjectAssetBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteProjectAssetBudgetSubscription = {
  __typename: "ProjectAssetBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectassetID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  ProjectAsset?: {
    __typename: "ProjectAsset";
    id: string;
    internalId?: string | null;
    type?: string | null;
    name?: string | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    TaxCreditUsages?: {
      __typename: "ModelTaxCreditUsageConnection";
      items?: Array<{
        __typename: "TaxCreditUsage";
        id: string;
        internalId?: string | null;
        type?: string | null;
        amount?: number | null;
        comment?: string | null;
        taxYear?: number | null;
        taxState?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    TaxCreditNotes?: {
      __typename: "ModelTaxCreditNoteConnection";
      items?: Array<{
        __typename: "TaxCreditNote";
        id: string;
        internalId?: string | null;
        irc41Category?: string | null;
        note?: string | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectAssetBudget?: {
      __typename: "ProjectAssetBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateProjectAssetSubscription = {
  __typename: "ProjectAsset";
  id: string;
  internalId?: string | null;
  type?: string | null;
  name?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  TaxCreditUsages?: {
    __typename: "ModelTaxCreditUsageConnection";
    items?: Array<{
      __typename: "TaxCreditUsage";
      id: string;
      internalId?: string | null;
      type?: string | null;
      amount?: number | null;
      comment?: string | null;
      taxYear?: number | null;
      taxState?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  TaxCreditNotes?: {
    __typename: "ModelTaxCreditNoteConnection";
    items?: Array<{
      __typename: "TaxCreditNote";
      id: string;
      internalId?: string | null;
      irc41Category?: string | null;
      note?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectAssetBudget?: {
    __typename: "ProjectAssetBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectassetID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    ProjectAsset?: {
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type OnUpdateProjectAssetSubscription = {
  __typename: "ProjectAsset";
  id: string;
  internalId?: string | null;
  type?: string | null;
  name?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  TaxCreditUsages?: {
    __typename: "ModelTaxCreditUsageConnection";
    items?: Array<{
      __typename: "TaxCreditUsage";
      id: string;
      internalId?: string | null;
      type?: string | null;
      amount?: number | null;
      comment?: string | null;
      taxYear?: number | null;
      taxState?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  TaxCreditNotes?: {
    __typename: "ModelTaxCreditNoteConnection";
    items?: Array<{
      __typename: "TaxCreditNote";
      id: string;
      internalId?: string | null;
      irc41Category?: string | null;
      note?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectAssetBudget?: {
    __typename: "ProjectAssetBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectassetID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    ProjectAsset?: {
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type OnDeleteProjectAssetSubscription = {
  __typename: "ProjectAsset";
  id: string;
  internalId?: string | null;
  type?: string | null;
  name?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  TaxCreditUsages?: {
    __typename: "ModelTaxCreditUsageConnection";
    items?: Array<{
      __typename: "TaxCreditUsage";
      id: string;
      internalId?: string | null;
      type?: string | null;
      amount?: number | null;
      comment?: string | null;
      taxYear?: number | null;
      taxState?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  TaxCreditNotes?: {
    __typename: "ModelTaxCreditNoteConnection";
    items?: Array<{
      __typename: "TaxCreditNote";
      id: string;
      internalId?: string | null;
      irc41Category?: string | null;
      note?: string | null;
      projectassetID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      ProjectAsset?: {
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectAssetBudget?: {
    __typename: "ProjectAssetBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectassetID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    ProjectAsset?: {
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type OnCreateProjectTaxCreditUsageSubscription = {
  __typename: "ProjectTaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type OnUpdateProjectTaxCreditUsageSubscription = {
  __typename: "ProjectTaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type OnDeleteProjectTaxCreditUsageSubscription = {
  __typename: "ProjectTaxCreditUsage";
  id: string;
  internalId?: string | null;
  type?: string | null;
  amount?: number | null;
  comment?: string | null;
  taxYear?: number | null;
  taxState?: string | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type OnCreateProjectBudgetSubscription = {
  __typename: "ProjectBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type OnUpdateProjectBudgetSubscription = {
  __typename: "ProjectBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type OnDeleteProjectBudgetSubscription = {
  __typename: "ProjectBudget";
  id: string;
  internalId?: string | null;
  budgetHours?: number | null;
  budgetAmount?: number | null;
  taxCreditPercent?: number | null;
  taxCreditHours?: number | null;
  taxCreditAmount?: number | null;
  totalTaxCredit?: number | null;
  projectID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Project?: {
    __typename: "Project";
    id: string;
    internalId?: string | null;
    name?: string | null;
    contactName?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    ProjectAssets?: {
      __typename: "ModelProjectAssetConnection";
      items?: Array<{
        __typename: "ProjectAsset";
        id: string;
        internalId?: string | null;
        type?: string | null;
        name?: string | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        TaxCreditUsages?: {
          __typename: "ModelTaxCreditUsageConnection";
          nextToken?: string | null;
        } | null;
        TaxCreditNotes?: {
          __typename: "ModelTaxCreditNoteConnection";
          nextToken?: string | null;
        } | null;
        ProjectAssetBudget?: {
          __typename: "ProjectAssetBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    ProjectBudget?: {
      __typename: "ProjectBudget";
      id: string;
      internalId?: string | null;
      budgetHours?: number | null;
      budgetAmount?: number | null;
      taxCreditPercent?: number | null;
      taxCreditHours?: number | null;
      taxCreditAmount?: number | null;
      totalTaxCredit?: number | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null;
    owner?: string | null;
    Client?: {
      __typename: "Client";
      id: string;
      internalId?: string | null;
      name?: string | null;
      street?: string | null;
      city?: string | null;
      zip?: string | null;
      country?: string | null;
      state?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      Projects?: {
        __typename: "ModelProjectConnection";
        items?: Array<{
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      owner?: string | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type OnCreateProjectSubscription = {
  __typename: "Project";
  id: string;
  internalId?: string | null;
  name?: string | null;
  contactName?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  ProjectAssets?: {
    __typename: "ModelProjectAssetConnection";
    items?: Array<{
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectBudget?: {
    __typename: "ProjectBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateProjectSubscription = {
  __typename: "Project";
  id: string;
  internalId?: string | null;
  name?: string | null;
  contactName?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  ProjectAssets?: {
    __typename: "ModelProjectAssetConnection";
    items?: Array<{
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectBudget?: {
    __typename: "ProjectBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteProjectSubscription = {
  __typename: "Project";
  id: string;
  internalId?: string | null;
  name?: string | null;
  contactName?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  ProjectAssets?: {
    __typename: "ModelProjectAssetConnection";
    items?: Array<{
      __typename: "ProjectAsset";
      id: string;
      internalId?: string | null;
      type?: string | null;
      name?: string | null;
      projectID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      TaxCreditUsages?: {
        __typename: "ModelTaxCreditUsageConnection";
        items?: Array<{
          __typename: "TaxCreditUsage";
          id: string;
          internalId?: string | null;
          type?: string | null;
          amount?: number | null;
          comment?: string | null;
          taxYear?: number | null;
          taxState?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      TaxCreditNotes?: {
        __typename: "ModelTaxCreditNoteConnection";
        items?: Array<{
          __typename: "TaxCreditNote";
          id: string;
          internalId?: string | null;
          irc41Category?: string | null;
          note?: string | null;
          projectassetID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectAssetBudget?: {
        __typename: "ProjectAssetBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectassetID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        ProjectAsset?: {
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Project?: {
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  ProjectBudget?: {
    __typename: "ProjectBudget";
    id: string;
    internalId?: string | null;
    budgetHours?: number | null;
    budgetAmount?: number | null;
    taxCreditPercent?: number | null;
    taxCreditHours?: number | null;
    taxCreditAmount?: number | null;
    totalTaxCredit?: number | null;
    projectID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
    Project?: {
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null;
  } | null;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnCreateClientNoteSubscription = {
  __typename: "ClientNote";
  id: string;
  internalId?: string | null;
  note?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type OnUpdateClientNoteSubscription = {
  __typename: "ClientNote";
  id: string;
  internalId?: string | null;
  note?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type OnDeleteClientNoteSubscription = {
  __typename: "ClientNote";
  id: string;
  internalId?: string | null;
  note?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
  Client?: {
    __typename: "Client";
    id: string;
    internalId?: string | null;
    name?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    country?: string | null;
    state?: string | null;
    description?: string | null;
    s3FileImageID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    Projects?: {
      __typename: "ModelProjectConnection";
      items?: Array<{
        __typename: "Project";
        id: string;
        internalId?: string | null;
        name?: string | null;
        contactName?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        ProjectAssets?: {
          __typename: "ModelProjectAssetConnection";
          nextToken?: string | null;
        } | null;
        ProjectBudget?: {
          __typename: "ProjectBudget";
          id: string;
          internalId?: string | null;
          budgetHours?: number | null;
          budgetAmount?: number | null;
          taxCreditPercent?: number | null;
          taxCreditHours?: number | null;
          taxCreditAmount?: number | null;
          totalTaxCredit?: number | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        owner?: string | null;
        Client?: {
          __typename: "Client";
          id: string;
          internalId?: string | null;
          name?: string | null;
          street?: string | null;
          city?: string | null;
          zip?: string | null;
          country?: string | null;
          state?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    owner?: string | null;
    s3fileImage?: {
      __typename: "S3File";
      id: string;
      internalId?: string | null;
      s3key?: string | null;
      s3IdentityId?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      owner?: string | null;
    } | null;
  } | null;
};

export type OnCreateClientSubscription = {
  __typename: "Client";
  id: string;
  internalId?: string | null;
  name?: string | null;
  street?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  state?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  Projects?: {
    __typename: "ModelProjectConnection";
    items?: Array<{
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateClientSubscription = {
  __typename: "Client";
  id: string;
  internalId?: string | null;
  name?: string | null;
  street?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  state?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  Projects?: {
    __typename: "ModelProjectConnection";
    items?: Array<{
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteClientSubscription = {
  __typename: "Client";
  id: string;
  internalId?: string | null;
  name?: string | null;
  street?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  state?: string | null;
  description?: string | null;
  s3FileImageID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  Projects?: {
    __typename: "ModelProjectConnection";
    items?: Array<{
      __typename: "Project";
      id: string;
      internalId?: string | null;
      name?: string | null;
      contactName?: string | null;
      description?: string | null;
      s3FileImageID?: string | null;
      clientID?: string | null;
      editGroup?: string | null;
      viewGroup?: string | null;
      createdAt: string;
      updatedAt: string;
      ProjectAssets?: {
        __typename: "ModelProjectAssetConnection";
        items?: Array<{
          __typename: "ProjectAsset";
          id: string;
          internalId?: string | null;
          type?: string | null;
          name?: string | null;
          projectID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null> | null;
        nextToken?: string | null;
      } | null;
      ProjectBudget?: {
        __typename: "ProjectBudget";
        id: string;
        internalId?: string | null;
        budgetHours?: number | null;
        budgetAmount?: number | null;
        taxCreditPercent?: number | null;
        taxCreditHours?: number | null;
        taxCreditAmount?: number | null;
        totalTaxCredit?: number | null;
        projectID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
        Project?: {
          __typename: "Project";
          id: string;
          internalId?: string | null;
          name?: string | null;
          contactName?: string | null;
          description?: string | null;
          s3FileImageID?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      owner?: string | null;
      Client?: {
        __typename: "Client";
        id: string;
        internalId?: string | null;
        name?: string | null;
        street?: string | null;
        city?: string | null;
        zip?: string | null;
        country?: string | null;
        state?: string | null;
        description?: string | null;
        s3FileImageID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        Projects?: {
          __typename: "ModelProjectConnection";
          nextToken?: string | null;
        } | null;
        owner?: string | null;
        s3fileImage?: {
          __typename: "S3File";
          id: string;
          internalId?: string | null;
          s3key?: string | null;
          s3IdentityId?: string | null;
          clientID?: string | null;
          editGroup?: string | null;
          viewGroup?: string | null;
          createdAt: string;
          updatedAt: string;
          owner?: string | null;
        } | null;
      } | null;
      s3fileImage?: {
        __typename: "S3File";
        id: string;
        internalId?: string | null;
        s3key?: string | null;
        s3IdentityId?: string | null;
        clientID?: string | null;
        editGroup?: string | null;
        viewGroup?: string | null;
        createdAt: string;
        updatedAt: string;
        owner?: string | null;
      } | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  owner?: string | null;
  s3fileImage?: {
    __typename: "S3File";
    id: string;
    internalId?: string | null;
    s3key?: string | null;
    s3IdentityId?: string | null;
    clientID?: string | null;
    editGroup?: string | null;
    viewGroup?: string | null;
    createdAt: string;
    updatedAt: string;
    owner?: string | null;
  } | null;
};

export type OnCreateS3FileSubscription = {
  __typename: "S3File";
  id: string;
  internalId?: string | null;
  s3key?: string | null;
  s3IdentityId?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type OnUpdateS3FileSubscription = {
  __typename: "S3File";
  id: string;
  internalId?: string | null;
  s3key?: string | null;
  s3IdentityId?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

export type OnDeleteS3FileSubscription = {
  __typename: "S3File";
  id: string;
  internalId?: string | null;
  s3key?: string | null;
  s3IdentityId?: string | null;
  clientID?: string | null;
  editGroup?: string | null;
  viewGroup?: string | null;
  createdAt: string;
  updatedAt: string;
  owner?: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateTaxCreditUsage(
    input: CreateTaxCreditUsageInput,
    condition?: ModelTaxCreditUsageConditionInput
  ): Promise<CreateTaxCreditUsageMutation> {
    const statement = `mutation CreateTaxCreditUsage($input: CreateTaxCreditUsageInput!, $condition: ModelTaxCreditUsageConditionInput) {
        createTaxCreditUsage(input: $input, condition: $condition) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTaxCreditUsageMutation>response.data.createTaxCreditUsage;
  }
  async UpdateTaxCreditUsage(
    input: UpdateTaxCreditUsageInput,
    condition?: ModelTaxCreditUsageConditionInput
  ): Promise<UpdateTaxCreditUsageMutation> {
    const statement = `mutation UpdateTaxCreditUsage($input: UpdateTaxCreditUsageInput!, $condition: ModelTaxCreditUsageConditionInput) {
        updateTaxCreditUsage(input: $input, condition: $condition) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTaxCreditUsageMutation>response.data.updateTaxCreditUsage;
  }
  async DeleteTaxCreditUsage(
    input: DeleteTaxCreditUsageInput,
    condition?: ModelTaxCreditUsageConditionInput
  ): Promise<DeleteTaxCreditUsageMutation> {
    const statement = `mutation DeleteTaxCreditUsage($input: DeleteTaxCreditUsageInput!, $condition: ModelTaxCreditUsageConditionInput) {
        deleteTaxCreditUsage(input: $input, condition: $condition) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTaxCreditUsageMutation>response.data.deleteTaxCreditUsage;
  }
  async CreateTaxCreditNote(
    input: CreateTaxCreditNoteInput,
    condition?: ModelTaxCreditNoteConditionInput
  ): Promise<CreateTaxCreditNoteMutation> {
    const statement = `mutation CreateTaxCreditNote($input: CreateTaxCreditNoteInput!, $condition: ModelTaxCreditNoteConditionInput) {
        createTaxCreditNote(input: $input, condition: $condition) {
          __typename
          id
          internalId
          irc41Category
          note
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTaxCreditNoteMutation>response.data.createTaxCreditNote;
  }
  async UpdateTaxCreditNote(
    input: UpdateTaxCreditNoteInput,
    condition?: ModelTaxCreditNoteConditionInput
  ): Promise<UpdateTaxCreditNoteMutation> {
    const statement = `mutation UpdateTaxCreditNote($input: UpdateTaxCreditNoteInput!, $condition: ModelTaxCreditNoteConditionInput) {
        updateTaxCreditNote(input: $input, condition: $condition) {
          __typename
          id
          internalId
          irc41Category
          note
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTaxCreditNoteMutation>response.data.updateTaxCreditNote;
  }
  async DeleteTaxCreditNote(
    input: DeleteTaxCreditNoteInput,
    condition?: ModelTaxCreditNoteConditionInput
  ): Promise<DeleteTaxCreditNoteMutation> {
    const statement = `mutation DeleteTaxCreditNote($input: DeleteTaxCreditNoteInput!, $condition: ModelTaxCreditNoteConditionInput) {
        deleteTaxCreditNote(input: $input, condition: $condition) {
          __typename
          id
          internalId
          irc41Category
          note
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTaxCreditNoteMutation>response.data.deleteTaxCreditNote;
  }
  async CreateProjectAssetBudget(
    input: CreateProjectAssetBudgetInput,
    condition?: ModelProjectAssetBudgetConditionInput
  ): Promise<CreateProjectAssetBudgetMutation> {
    const statement = `mutation CreateProjectAssetBudget($input: CreateProjectAssetBudgetInput!, $condition: ModelProjectAssetBudgetConditionInput) {
        createProjectAssetBudget(input: $input, condition: $condition) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProjectAssetBudgetMutation>(
      response.data.createProjectAssetBudget
    );
  }
  async UpdateProjectAssetBudget(
    input: UpdateProjectAssetBudgetInput,
    condition?: ModelProjectAssetBudgetConditionInput
  ): Promise<UpdateProjectAssetBudgetMutation> {
    const statement = `mutation UpdateProjectAssetBudget($input: UpdateProjectAssetBudgetInput!, $condition: ModelProjectAssetBudgetConditionInput) {
        updateProjectAssetBudget(input: $input, condition: $condition) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProjectAssetBudgetMutation>(
      response.data.updateProjectAssetBudget
    );
  }
  async DeleteProjectAssetBudget(
    input: DeleteProjectAssetBudgetInput,
    condition?: ModelProjectAssetBudgetConditionInput
  ): Promise<DeleteProjectAssetBudgetMutation> {
    const statement = `mutation DeleteProjectAssetBudget($input: DeleteProjectAssetBudgetInput!, $condition: ModelProjectAssetBudgetConditionInput) {
        deleteProjectAssetBudget(input: $input, condition: $condition) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProjectAssetBudgetMutation>(
      response.data.deleteProjectAssetBudget
    );
  }
  async CreateProjectAsset(
    input: CreateProjectAssetInput,
    condition?: ModelProjectAssetConditionInput
  ): Promise<CreateProjectAssetMutation> {
    const statement = `mutation CreateProjectAsset($input: CreateProjectAssetInput!, $condition: ModelProjectAssetConditionInput) {
        createProjectAsset(input: $input, condition: $condition) {
          __typename
          id
          internalId
          type
          name
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          TaxCreditUsages {
            __typename
            items {
              __typename
              id
              internalId
              type
              amount
              comment
              taxYear
              taxState
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          TaxCreditNotes {
            __typename
            items {
              __typename
              id
              internalId
              irc41Category
              note
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectAssetBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectassetID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            ProjectAsset {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
          }
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProjectAssetMutation>response.data.createProjectAsset;
  }
  async UpdateProjectAsset(
    input: UpdateProjectAssetInput,
    condition?: ModelProjectAssetConditionInput
  ): Promise<UpdateProjectAssetMutation> {
    const statement = `mutation UpdateProjectAsset($input: UpdateProjectAssetInput!, $condition: ModelProjectAssetConditionInput) {
        updateProjectAsset(input: $input, condition: $condition) {
          __typename
          id
          internalId
          type
          name
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          TaxCreditUsages {
            __typename
            items {
              __typename
              id
              internalId
              type
              amount
              comment
              taxYear
              taxState
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          TaxCreditNotes {
            __typename
            items {
              __typename
              id
              internalId
              irc41Category
              note
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectAssetBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectassetID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            ProjectAsset {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
          }
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProjectAssetMutation>response.data.updateProjectAsset;
  }
  async DeleteProjectAsset(
    input: DeleteProjectAssetInput,
    condition?: ModelProjectAssetConditionInput
  ): Promise<DeleteProjectAssetMutation> {
    const statement = `mutation DeleteProjectAsset($input: DeleteProjectAssetInput!, $condition: ModelProjectAssetConditionInput) {
        deleteProjectAsset(input: $input, condition: $condition) {
          __typename
          id
          internalId
          type
          name
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          TaxCreditUsages {
            __typename
            items {
              __typename
              id
              internalId
              type
              amount
              comment
              taxYear
              taxState
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          TaxCreditNotes {
            __typename
            items {
              __typename
              id
              internalId
              irc41Category
              note
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectAssetBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectassetID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            ProjectAsset {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
          }
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProjectAssetMutation>response.data.deleteProjectAsset;
  }
  async CreateProjectTaxCreditUsage(
    input: CreateProjectTaxCreditUsageInput,
    condition?: ModelProjectTaxCreditUsageConditionInput
  ): Promise<CreateProjectTaxCreditUsageMutation> {
    const statement = `mutation CreateProjectTaxCreditUsage($input: CreateProjectTaxCreditUsageInput!, $condition: ModelProjectTaxCreditUsageConditionInput) {
        createProjectTaxCreditUsage(input: $input, condition: $condition) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProjectTaxCreditUsageMutation>(
      response.data.createProjectTaxCreditUsage
    );
  }
  async UpdateProjectTaxCreditUsage(
    input: UpdateProjectTaxCreditUsageInput,
    condition?: ModelProjectTaxCreditUsageConditionInput
  ): Promise<UpdateProjectTaxCreditUsageMutation> {
    const statement = `mutation UpdateProjectTaxCreditUsage($input: UpdateProjectTaxCreditUsageInput!, $condition: ModelProjectTaxCreditUsageConditionInput) {
        updateProjectTaxCreditUsage(input: $input, condition: $condition) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProjectTaxCreditUsageMutation>(
      response.data.updateProjectTaxCreditUsage
    );
  }
  async DeleteProjectTaxCreditUsage(
    input: DeleteProjectTaxCreditUsageInput,
    condition?: ModelProjectTaxCreditUsageConditionInput
  ): Promise<DeleteProjectTaxCreditUsageMutation> {
    const statement = `mutation DeleteProjectTaxCreditUsage($input: DeleteProjectTaxCreditUsageInput!, $condition: ModelProjectTaxCreditUsageConditionInput) {
        deleteProjectTaxCreditUsage(input: $input, condition: $condition) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProjectTaxCreditUsageMutation>(
      response.data.deleteProjectTaxCreditUsage
    );
  }
  async CreateProjectBudget(
    input: CreateProjectBudgetInput,
    condition?: ModelProjectBudgetConditionInput
  ): Promise<CreateProjectBudgetMutation> {
    const statement = `mutation CreateProjectBudget($input: CreateProjectBudgetInput!, $condition: ModelProjectBudgetConditionInput) {
        createProjectBudget(input: $input, condition: $condition) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProjectBudgetMutation>response.data.createProjectBudget;
  }
  async UpdateProjectBudget(
    input: UpdateProjectBudgetInput,
    condition?: ModelProjectBudgetConditionInput
  ): Promise<UpdateProjectBudgetMutation> {
    const statement = `mutation UpdateProjectBudget($input: UpdateProjectBudgetInput!, $condition: ModelProjectBudgetConditionInput) {
        updateProjectBudget(input: $input, condition: $condition) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProjectBudgetMutation>response.data.updateProjectBudget;
  }
  async DeleteProjectBudget(
    input: DeleteProjectBudgetInput,
    condition?: ModelProjectBudgetConditionInput
  ): Promise<DeleteProjectBudgetMutation> {
    const statement = `mutation DeleteProjectBudget($input: DeleteProjectBudgetInput!, $condition: ModelProjectBudgetConditionInput) {
        deleteProjectBudget(input: $input, condition: $condition) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProjectBudgetMutation>response.data.deleteProjectBudget;
  }
  async CreateProject(
    input: CreateProjectInput,
    condition?: ModelProjectConditionInput
  ): Promise<CreateProjectMutation> {
    const statement = `mutation CreateProject($input: CreateProjectInput!, $condition: ModelProjectConditionInput) {
        createProject(input: $input, condition: $condition) {
          __typename
          id
          internalId
          name
          contactName
          description
          s3FileImageID
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          ProjectAssets {
            __typename
            items {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProjectMutation>response.data.createProject;
  }
  async UpdateProject(
    input: UpdateProjectInput,
    condition?: ModelProjectConditionInput
  ): Promise<UpdateProjectMutation> {
    const statement = `mutation UpdateProject($input: UpdateProjectInput!, $condition: ModelProjectConditionInput) {
        updateProject(input: $input, condition: $condition) {
          __typename
          id
          internalId
          name
          contactName
          description
          s3FileImageID
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          ProjectAssets {
            __typename
            items {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProjectMutation>response.data.updateProject;
  }
  async DeleteProject(
    input: DeleteProjectInput,
    condition?: ModelProjectConditionInput
  ): Promise<DeleteProjectMutation> {
    const statement = `mutation DeleteProject($input: DeleteProjectInput!, $condition: ModelProjectConditionInput) {
        deleteProject(input: $input, condition: $condition) {
          __typename
          id
          internalId
          name
          contactName
          description
          s3FileImageID
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          ProjectAssets {
            __typename
            items {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProjectMutation>response.data.deleteProject;
  }
  async CreateClientNote(
    input: CreateClientNoteInput,
    condition?: ModelClientNoteConditionInput
  ): Promise<CreateClientNoteMutation> {
    const statement = `mutation CreateClientNote($input: CreateClientNoteInput!, $condition: ModelClientNoteConditionInput) {
        createClientNote(input: $input, condition: $condition) {
          __typename
          id
          internalId
          note
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateClientNoteMutation>response.data.createClientNote;
  }
  async UpdateClientNote(
    input: UpdateClientNoteInput,
    condition?: ModelClientNoteConditionInput
  ): Promise<UpdateClientNoteMutation> {
    const statement = `mutation UpdateClientNote($input: UpdateClientNoteInput!, $condition: ModelClientNoteConditionInput) {
        updateClientNote(input: $input, condition: $condition) {
          __typename
          id
          internalId
          note
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateClientNoteMutation>response.data.updateClientNote;
  }
  async DeleteClientNote(
    input: DeleteClientNoteInput,
    condition?: ModelClientNoteConditionInput
  ): Promise<DeleteClientNoteMutation> {
    const statement = `mutation DeleteClientNote($input: DeleteClientNoteInput!, $condition: ModelClientNoteConditionInput) {
        deleteClientNote(input: $input, condition: $condition) {
          __typename
          id
          internalId
          note
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteClientNoteMutation>response.data.deleteClientNote;
  }
  async CreateClient(
    input: CreateClientInput,
    condition?: ModelClientConditionInput
  ): Promise<CreateClientMutation> {
    const statement = `mutation CreateClient($input: CreateClientInput!, $condition: ModelClientConditionInput) {
        createClient(input: $input, condition: $condition) {
          __typename
          id
          internalId
          name
          street
          city
          zip
          country
          state
          description
          s3FileImageID
          editGroup
          viewGroup
          createdAt
          updatedAt
          Projects {
            __typename
            items {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            nextToken
          }
          owner
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateClientMutation>response.data.createClient;
  }
  async UpdateClient(
    input: UpdateClientInput,
    condition?: ModelClientConditionInput
  ): Promise<UpdateClientMutation> {
    const statement = `mutation UpdateClient($input: UpdateClientInput!, $condition: ModelClientConditionInput) {
        updateClient(input: $input, condition: $condition) {
          __typename
          id
          internalId
          name
          street
          city
          zip
          country
          state
          description
          s3FileImageID
          editGroup
          viewGroup
          createdAt
          updatedAt
          Projects {
            __typename
            items {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            nextToken
          }
          owner
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateClientMutation>response.data.updateClient;
  }
  async DeleteClient(
    input: DeleteClientInput,
    condition?: ModelClientConditionInput
  ): Promise<DeleteClientMutation> {
    const statement = `mutation DeleteClient($input: DeleteClientInput!, $condition: ModelClientConditionInput) {
        deleteClient(input: $input, condition: $condition) {
          __typename
          id
          internalId
          name
          street
          city
          zip
          country
          state
          description
          s3FileImageID
          editGroup
          viewGroup
          createdAt
          updatedAt
          Projects {
            __typename
            items {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            nextToken
          }
          owner
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteClientMutation>response.data.deleteClient;
  }
  async CreateS3File(
    input: CreateS3FileInput,
    condition?: ModelS3FileConditionInput
  ): Promise<CreateS3FileMutation> {
    const statement = `mutation CreateS3File($input: CreateS3FileInput!, $condition: ModelS3FileConditionInput) {
        createS3File(input: $input, condition: $condition) {
          __typename
          id
          internalId
          s3key
          s3IdentityId
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateS3FileMutation>response.data.createS3File;
  }
  async UpdateS3File(
    input: UpdateS3FileInput,
    condition?: ModelS3FileConditionInput
  ): Promise<UpdateS3FileMutation> {
    const statement = `mutation UpdateS3File($input: UpdateS3FileInput!, $condition: ModelS3FileConditionInput) {
        updateS3File(input: $input, condition: $condition) {
          __typename
          id
          internalId
          s3key
          s3IdentityId
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateS3FileMutation>response.data.updateS3File;
  }
  async DeleteS3File(
    input: DeleteS3FileInput,
    condition?: ModelS3FileConditionInput
  ): Promise<DeleteS3FileMutation> {
    const statement = `mutation DeleteS3File($input: DeleteS3FileInput!, $condition: ModelS3FileConditionInput) {
        deleteS3File(input: $input, condition: $condition) {
          __typename
          id
          internalId
          s3key
          s3IdentityId
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteS3FileMutation>response.data.deleteS3File;
  }
  async GetTaxCreditUsage(id: string): Promise<GetTaxCreditUsageQuery> {
    const statement = `query GetTaxCreditUsage($id: ID!) {
        getTaxCreditUsage(id: $id) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTaxCreditUsageQuery>response.data.getTaxCreditUsage;
  }
  async ListTaxCreditUsages(
    filter?: ModelTaxCreditUsageFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTaxCreditUsagesQuery> {
    const statement = `query ListTaxCreditUsages($filter: ModelTaxCreditUsageFilterInput, $limit: Int, $nextToken: String) {
        listTaxCreditUsages(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            internalId
            type
            amount
            comment
            taxYear
            taxState
            projectassetID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            ProjectAsset {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTaxCreditUsagesQuery>response.data.listTaxCreditUsages;
  }
  async GetTaxCreditNote(id: string): Promise<GetTaxCreditNoteQuery> {
    const statement = `query GetTaxCreditNote($id: ID!) {
        getTaxCreditNote(id: $id) {
          __typename
          id
          internalId
          irc41Category
          note
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTaxCreditNoteQuery>response.data.getTaxCreditNote;
  }
  async ListTaxCreditNotes(
    filter?: ModelTaxCreditNoteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTaxCreditNotesQuery> {
    const statement = `query ListTaxCreditNotes($filter: ModelTaxCreditNoteFilterInput, $limit: Int, $nextToken: String) {
        listTaxCreditNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            internalId
            irc41Category
            note
            projectassetID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            ProjectAsset {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTaxCreditNotesQuery>response.data.listTaxCreditNotes;
  }
  async ListProjectAssetBudgets(
    filter?: ModelProjectAssetBudgetFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProjectAssetBudgetsQuery> {
    const statement = `query ListProjectAssetBudgets($filter: ModelProjectAssetBudgetFilterInput, $limit: Int, $nextToken: String) {
        listProjectAssetBudgets(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectassetID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            ProjectAsset {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProjectAssetBudgetsQuery>response.data.listProjectAssetBudgets;
  }
  async GetProjectAssetBudget(id: string): Promise<GetProjectAssetBudgetQuery> {
    const statement = `query GetProjectAssetBudget($id: ID!) {
        getProjectAssetBudget(id: $id) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProjectAssetBudgetQuery>response.data.getProjectAssetBudget;
  }
  async GetProjectAsset(id: string): Promise<GetProjectAssetQuery> {
    const statement = `query GetProjectAsset($id: ID!) {
        getProjectAsset(id: $id) {
          __typename
          id
          internalId
          type
          name
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          TaxCreditUsages {
            __typename
            items {
              __typename
              id
              internalId
              type
              amount
              comment
              taxYear
              taxState
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          TaxCreditNotes {
            __typename
            items {
              __typename
              id
              internalId
              irc41Category
              note
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectAssetBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectassetID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            ProjectAsset {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
          }
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProjectAssetQuery>response.data.getProjectAsset;
  }
  async ListProjectAssets(
    filter?: ModelProjectAssetFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProjectAssetsQuery> {
    const statement = `query ListProjectAssets($filter: ModelProjectAssetFilterInput, $limit: Int, $nextToken: String) {
        listProjectAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProjectAssetsQuery>response.data.listProjectAssets;
  }
  async GetProjectTaxCreditUsage(
    id: string
  ): Promise<GetProjectTaxCreditUsageQuery> {
    const statement = `query GetProjectTaxCreditUsage($id: ID!) {
        getProjectTaxCreditUsage(id: $id) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProjectTaxCreditUsageQuery>(
      response.data.getProjectTaxCreditUsage
    );
  }
  async ListProjectTaxCreditUsages(
    filter?: ModelProjectTaxCreditUsageFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProjectTaxCreditUsagesQuery> {
    const statement = `query ListProjectTaxCreditUsages($filter: ModelProjectTaxCreditUsageFilterInput, $limit: Int, $nextToken: String) {
        listProjectTaxCreditUsages(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            internalId
            type
            amount
            comment
            taxYear
            taxState
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProjectTaxCreditUsagesQuery>(
      response.data.listProjectTaxCreditUsages
    );
  }
  async ListProjectBudgets(
    filter?: ModelProjectBudgetFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProjectBudgetsQuery> {
    const statement = `query ListProjectBudgets($filter: ModelProjectBudgetFilterInput, $limit: Int, $nextToken: String) {
        listProjectBudgets(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProjectBudgetsQuery>response.data.listProjectBudgets;
  }
  async GetProjectBudget(id: string): Promise<GetProjectBudgetQuery> {
    const statement = `query GetProjectBudget($id: ID!) {
        getProjectBudget(id: $id) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProjectBudgetQuery>response.data.getProjectBudget;
  }
  async GetProject(id: string): Promise<GetProjectQuery> {
    const statement = `query GetProject($id: ID!) {
        getProject(id: $id) {
          __typename
          id
          internalId
          name
          contactName
          description
          s3FileImageID
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          ProjectAssets {
            __typename
            items {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProjectQuery>response.data.getProject;
  }
  async ListProjects(
    filter?: ModelProjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProjectsQuery> {
    const statement = `query ListProjects($filter: ModelProjectFilterInput, $limit: Int, $nextToken: String) {
        listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProjectsQuery>response.data.listProjects;
  }
  async GetClientNote(id: string): Promise<GetClientNoteQuery> {
    const statement = `query GetClientNote($id: ID!) {
        getClientNote(id: $id) {
          __typename
          id
          internalId
          note
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetClientNoteQuery>response.data.getClientNote;
  }
  async ListClientNotes(
    filter?: ModelClientNoteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListClientNotesQuery> {
    const statement = `query ListClientNotes($filter: ModelClientNoteFilterInput, $limit: Int, $nextToken: String) {
        listClientNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            internalId
            note
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListClientNotesQuery>response.data.listClientNotes;
  }
  async ListClients(
    filter?: ModelClientFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListClientsQuery> {
    const statement = `query ListClients($filter: ModelClientFilterInput, $limit: Int, $nextToken: String) {
        listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListClientsQuery>response.data.listClients;
  }
  async GetClient(id: string): Promise<GetClientQuery> {
    const statement = `query GetClient($id: ID!) {
        getClient(id: $id) {
          __typename
          id
          internalId
          name
          street
          city
          zip
          country
          state
          description
          s3FileImageID
          editGroup
          viewGroup
          createdAt
          updatedAt
          Projects {
            __typename
            items {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            nextToken
          }
          owner
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetClientQuery>response.data.getClient;
  }
  async ListS3Files(
    filter?: ModelS3FileFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListS3FilesQuery> {
    const statement = `query ListS3Files($filter: ModelS3FileFilterInput, $limit: Int, $nextToken: String) {
        listS3Files(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListS3FilesQuery>response.data.listS3Files;
  }
  async GetS3File(id: string): Promise<GetS3FileQuery> {
    const statement = `query GetS3File($id: ID!) {
        getS3File(id: $id) {
          __typename
          id
          internalId
          s3key
          s3IdentityId
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetS3FileQuery>response.data.getS3File;
  }
  OnCreateTaxCreditUsageListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnCreateTaxCreditUsageSubscription>> {
    const statement = `subscription OnCreateTaxCreditUsage($owner: String) {
        onCreateTaxCreditUsage(owner: $owner) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnCreateTaxCreditUsageSubscription>>;
  }

  OnUpdateTaxCreditUsageListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnUpdateTaxCreditUsageSubscription>> {
    const statement = `subscription OnUpdateTaxCreditUsage($owner: String) {
        onUpdateTaxCreditUsage(owner: $owner) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnUpdateTaxCreditUsageSubscription>>;
  }

  OnDeleteTaxCreditUsageListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnDeleteTaxCreditUsageSubscription>> {
    const statement = `subscription OnDeleteTaxCreditUsage($owner: String) {
        onDeleteTaxCreditUsage(owner: $owner) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnDeleteTaxCreditUsageSubscription>>;
  }

  OnCreateTaxCreditNoteListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnCreateTaxCreditNoteSubscription>> {
    const statement = `subscription OnCreateTaxCreditNote($owner: String) {
        onCreateTaxCreditNote(owner: $owner) {
          __typename
          id
          internalId
          irc41Category
          note
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnCreateTaxCreditNoteSubscription>>;
  }

  OnUpdateTaxCreditNoteListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnUpdateTaxCreditNoteSubscription>> {
    const statement = `subscription OnUpdateTaxCreditNote($owner: String) {
        onUpdateTaxCreditNote(owner: $owner) {
          __typename
          id
          internalId
          irc41Category
          note
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnUpdateTaxCreditNoteSubscription>>;
  }

  OnDeleteTaxCreditNoteListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnDeleteTaxCreditNoteSubscription>> {
    const statement = `subscription OnDeleteTaxCreditNote($owner: String) {
        onDeleteTaxCreditNote(owner: $owner) {
          __typename
          id
          internalId
          irc41Category
          note
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnDeleteTaxCreditNoteSubscription>>;
  }

  OnCreateProjectAssetBudgetListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnCreateProjectAssetBudgetSubscription>> {
    const statement = `subscription OnCreateProjectAssetBudget($owner: String) {
        onCreateProjectAssetBudget(owner: $owner) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<OnCreateProjectAssetBudgetSubscription>
    >;
  }

  OnUpdateProjectAssetBudgetListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnUpdateProjectAssetBudgetSubscription>> {
    const statement = `subscription OnUpdateProjectAssetBudget($owner: String) {
        onUpdateProjectAssetBudget(owner: $owner) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<OnUpdateProjectAssetBudgetSubscription>
    >;
  }

  OnDeleteProjectAssetBudgetListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnDeleteProjectAssetBudgetSubscription>> {
    const statement = `subscription OnDeleteProjectAssetBudget($owner: String) {
        onDeleteProjectAssetBudget(owner: $owner) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectassetID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          ProjectAsset {
            __typename
            id
            internalId
            type
            name
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            TaxCreditUsages {
              __typename
              items {
                __typename
                id
                internalId
                type
                amount
                comment
                taxYear
                taxState
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            TaxCreditNotes {
              __typename
              items {
                __typename
                id
                internalId
                irc41Category
                note
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectAssetBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<OnDeleteProjectAssetBudgetSubscription>
    >;
  }

  OnCreateProjectAssetListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnCreateProjectAssetSubscription>> {
    const statement = `subscription OnCreateProjectAsset($owner: String) {
        onCreateProjectAsset(owner: $owner) {
          __typename
          id
          internalId
          type
          name
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          TaxCreditUsages {
            __typename
            items {
              __typename
              id
              internalId
              type
              amount
              comment
              taxYear
              taxState
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          TaxCreditNotes {
            __typename
            items {
              __typename
              id
              internalId
              irc41Category
              note
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectAssetBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectassetID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            ProjectAsset {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
          }
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnCreateProjectAssetSubscription>>;
  }

  OnUpdateProjectAssetListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnUpdateProjectAssetSubscription>> {
    const statement = `subscription OnUpdateProjectAsset($owner: String) {
        onUpdateProjectAsset(owner: $owner) {
          __typename
          id
          internalId
          type
          name
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          TaxCreditUsages {
            __typename
            items {
              __typename
              id
              internalId
              type
              amount
              comment
              taxYear
              taxState
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          TaxCreditNotes {
            __typename
            items {
              __typename
              id
              internalId
              irc41Category
              note
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectAssetBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectassetID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            ProjectAsset {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
          }
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnUpdateProjectAssetSubscription>>;
  }

  OnDeleteProjectAssetListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnDeleteProjectAssetSubscription>> {
    const statement = `subscription OnDeleteProjectAsset($owner: String) {
        onDeleteProjectAsset(owner: $owner) {
          __typename
          id
          internalId
          type
          name
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          TaxCreditUsages {
            __typename
            items {
              __typename
              id
              internalId
              type
              amount
              comment
              taxYear
              taxState
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          TaxCreditNotes {
            __typename
            items {
              __typename
              id
              internalId
              irc41Category
              note
              projectassetID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              ProjectAsset {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectAssetBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectassetID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            ProjectAsset {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
          }
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnDeleteProjectAssetSubscription>>;
  }

  OnCreateProjectTaxCreditUsageListener(
    owner?: string
  ): Observable<
    SubscriptionResponse<OnCreateProjectTaxCreditUsageSubscription>
  > {
    const statement = `subscription OnCreateProjectTaxCreditUsage($owner: String) {
        onCreateProjectTaxCreditUsage(owner: $owner) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<OnCreateProjectTaxCreditUsageSubscription>
    >;
  }

  OnUpdateProjectTaxCreditUsageListener(
    owner?: string
  ): Observable<
    SubscriptionResponse<OnUpdateProjectTaxCreditUsageSubscription>
  > {
    const statement = `subscription OnUpdateProjectTaxCreditUsage($owner: String) {
        onUpdateProjectTaxCreditUsage(owner: $owner) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<OnUpdateProjectTaxCreditUsageSubscription>
    >;
  }

  OnDeleteProjectTaxCreditUsageListener(
    owner?: string
  ): Observable<
    SubscriptionResponse<OnDeleteProjectTaxCreditUsageSubscription>
  > {
    const statement = `subscription OnDeleteProjectTaxCreditUsage($owner: String) {
        onDeleteProjectTaxCreditUsage(owner: $owner) {
          __typename
          id
          internalId
          type
          amount
          comment
          taxYear
          taxState
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<OnDeleteProjectTaxCreditUsageSubscription>
    >;
  }

  OnCreateProjectBudgetListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnCreateProjectBudgetSubscription>> {
    const statement = `subscription OnCreateProjectBudget($owner: String) {
        onCreateProjectBudget(owner: $owner) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnCreateProjectBudgetSubscription>>;
  }

  OnUpdateProjectBudgetListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnUpdateProjectBudgetSubscription>> {
    const statement = `subscription OnUpdateProjectBudget($owner: String) {
        onUpdateProjectBudget(owner: $owner) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnUpdateProjectBudgetSubscription>>;
  }

  OnDeleteProjectBudgetListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnDeleteProjectBudgetSubscription>> {
    const statement = `subscription OnDeleteProjectBudget($owner: String) {
        onDeleteProjectBudget(owner: $owner) {
          __typename
          id
          internalId
          budgetHours
          budgetAmount
          taxCreditPercent
          taxCreditHours
          taxCreditAmount
          totalTaxCredit
          projectID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Project {
            __typename
            id
            internalId
            name
            contactName
            description
            s3FileImageID
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            ProjectAssets {
              __typename
              items {
                __typename
                id
                internalId
                type
                name
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                TaxCreditUsages {
                  __typename
                  nextToken
                }
                TaxCreditNotes {
                  __typename
                  nextToken
                }
                ProjectAssetBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            ProjectBudget {
              __typename
              id
              internalId
              budgetHours
              budgetAmount
              taxCreditPercent
              taxCreditHours
              taxCreditAmount
              totalTaxCredit
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            owner
            Client {
              __typename
              id
              internalId
              name
              street
              city
              zip
              country
              state
              description
              s3FileImageID
              editGroup
              viewGroup
              createdAt
              updatedAt
              Projects {
                __typename
                items {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              owner
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnDeleteProjectBudgetSubscription>>;
  }

  OnCreateProjectListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnCreateProjectSubscription>> {
    const statement = `subscription OnCreateProject($owner: String) {
        onCreateProject(owner: $owner) {
          __typename
          id
          internalId
          name
          contactName
          description
          s3FileImageID
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          ProjectAssets {
            __typename
            items {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnCreateProjectSubscription>>;
  }

  OnUpdateProjectListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnUpdateProjectSubscription>> {
    const statement = `subscription OnUpdateProject($owner: String) {
        onUpdateProject(owner: $owner) {
          __typename
          id
          internalId
          name
          contactName
          description
          s3FileImageID
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          ProjectAssets {
            __typename
            items {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnUpdateProjectSubscription>>;
  }

  OnDeleteProjectListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnDeleteProjectSubscription>> {
    const statement = `subscription OnDeleteProject($owner: String) {
        onDeleteProject(owner: $owner) {
          __typename
          id
          internalId
          name
          contactName
          description
          s3FileImageID
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          ProjectAssets {
            __typename
            items {
              __typename
              id
              internalId
              type
              name
              projectID
              editGroup
              viewGroup
              createdAt
              updatedAt
              TaxCreditUsages {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  amount
                  comment
                  taxYear
                  taxState
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              TaxCreditNotes {
                __typename
                items {
                  __typename
                  id
                  internalId
                  irc41Category
                  note
                  projectassetID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectAssetBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectassetID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                ProjectAsset {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Project {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
            }
            nextToken
          }
          ProjectBudget {
            __typename
            id
            internalId
            budgetHours
            budgetAmount
            taxCreditPercent
            taxCreditHours
            taxCreditAmount
            totalTaxCredit
            projectID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
            Project {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
          }
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnDeleteProjectSubscription>>;
  }

  OnCreateClientNoteListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnCreateClientNoteSubscription>> {
    const statement = `subscription OnCreateClientNote($owner: String) {
        onCreateClientNote(owner: $owner) {
          __typename
          id
          internalId
          note
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnCreateClientNoteSubscription>>;
  }

  OnUpdateClientNoteListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnUpdateClientNoteSubscription>> {
    const statement = `subscription OnUpdateClientNote($owner: String) {
        onUpdateClientNote(owner: $owner) {
          __typename
          id
          internalId
          note
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnUpdateClientNoteSubscription>>;
  }

  OnDeleteClientNoteListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnDeleteClientNoteSubscription>> {
    const statement = `subscription OnDeleteClientNote($owner: String) {
        onDeleteClientNote(owner: $owner) {
          __typename
          id
          internalId
          note
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
          Client {
            __typename
            id
            internalId
            name
            street
            city
            zip
            country
            state
            description
            s3FileImageID
            editGroup
            viewGroup
            createdAt
            updatedAt
            Projects {
              __typename
              items {
                __typename
                id
                internalId
                name
                contactName
                description
                s3FileImageID
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                ProjectAssets {
                  __typename
                  nextToken
                }
                ProjectBudget {
                  __typename
                  id
                  internalId
                  budgetHours
                  budgetAmount
                  taxCreditPercent
                  taxCreditHours
                  taxCreditAmount
                  totalTaxCredit
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                owner
                Client {
                  __typename
                  id
                  internalId
                  name
                  street
                  city
                  zip
                  country
                  state
                  description
                  s3FileImageID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              nextToken
            }
            owner
            s3fileImage {
              __typename
              id
              internalId
              s3key
              s3IdentityId
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              owner
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnDeleteClientNoteSubscription>>;
  }

  OnCreateClientListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnCreateClientSubscription>> {
    const statement = `subscription OnCreateClient($owner: String) {
        onCreateClient(owner: $owner) {
          __typename
          id
          internalId
          name
          street
          city
          zip
          country
          state
          description
          s3FileImageID
          editGroup
          viewGroup
          createdAt
          updatedAt
          Projects {
            __typename
            items {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            nextToken
          }
          owner
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnCreateClientSubscription>>;
  }

  OnUpdateClientListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnUpdateClientSubscription>> {
    const statement = `subscription OnUpdateClient($owner: String) {
        onUpdateClient(owner: $owner) {
          __typename
          id
          internalId
          name
          street
          city
          zip
          country
          state
          description
          s3FileImageID
          editGroup
          viewGroup
          createdAt
          updatedAt
          Projects {
            __typename
            items {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            nextToken
          }
          owner
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnUpdateClientSubscription>>;
  }

  OnDeleteClientListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnDeleteClientSubscription>> {
    const statement = `subscription OnDeleteClient($owner: String) {
        onDeleteClient(owner: $owner) {
          __typename
          id
          internalId
          name
          street
          city
          zip
          country
          state
          description
          s3FileImageID
          editGroup
          viewGroup
          createdAt
          updatedAt
          Projects {
            __typename
            items {
              __typename
              id
              internalId
              name
              contactName
              description
              s3FileImageID
              clientID
              editGroup
              viewGroup
              createdAt
              updatedAt
              ProjectAssets {
                __typename
                items {
                  __typename
                  id
                  internalId
                  type
                  name
                  projectID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              ProjectBudget {
                __typename
                id
                internalId
                budgetHours
                budgetAmount
                taxCreditPercent
                taxCreditHours
                taxCreditAmount
                totalTaxCredit
                projectID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
                Project {
                  __typename
                  id
                  internalId
                  name
                  contactName
                  description
                  s3FileImageID
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              owner
              Client {
                __typename
                id
                internalId
                name
                street
                city
                zip
                country
                state
                description
                s3FileImageID
                editGroup
                viewGroup
                createdAt
                updatedAt
                Projects {
                  __typename
                  nextToken
                }
                owner
                s3fileImage {
                  __typename
                  id
                  internalId
                  s3key
                  s3IdentityId
                  clientID
                  editGroup
                  viewGroup
                  createdAt
                  updatedAt
                  owner
                }
              }
              s3fileImage {
                __typename
                id
                internalId
                s3key
                s3IdentityId
                clientID
                editGroup
                viewGroup
                createdAt
                updatedAt
                owner
              }
            }
            nextToken
          }
          owner
          s3fileImage {
            __typename
            id
            internalId
            s3key
            s3IdentityId
            clientID
            editGroup
            viewGroup
            createdAt
            updatedAt
            owner
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnDeleteClientSubscription>>;
  }

  OnCreateS3FileListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnCreateS3FileSubscription>> {
    const statement = `subscription OnCreateS3File($owner: String) {
        onCreateS3File(owner: $owner) {
          __typename
          id
          internalId
          s3key
          s3IdentityId
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnCreateS3FileSubscription>>;
  }

  OnUpdateS3FileListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnUpdateS3FileSubscription>> {
    const statement = `subscription OnUpdateS3File($owner: String) {
        onUpdateS3File(owner: $owner) {
          __typename
          id
          internalId
          s3key
          s3IdentityId
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnUpdateS3FileSubscription>>;
  }

  OnDeleteS3FileListener(
    owner?: string
  ): Observable<SubscriptionResponse<OnDeleteS3FileSubscription>> {
    const statement = `subscription OnDeleteS3File($owner: String) {
        onDeleteS3File(owner: $owner) {
          __typename
          id
          internalId
          s3key
          s3IdentityId
          clientID
          editGroup
          viewGroup
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<SubscriptionResponse<OnDeleteS3FileSubscription>>;
  }
}
