<!-- <mat-nav-list>
    <a *ngFor="let projectTaxCreditUsage of projectTaxCreditUsages" mat-list-item [routerLink]="['/project-tax-credit-usage', projectTaxCreditUsage.id]">
        <h3 matLine><span *ngIf="projectTaxCreditUsage.internalId">{{projectTaxCreditUsage.internalId}} </span></h3>
        <p matLine>Tax Credit Usage - <b>{{projectTaxCreditUsage.type}}</b> </p>
        <p matLine>Project Module - <b>{{projectTaxCreditUsage.Project?.name}}</b></p>
        <p matLine>Amount - <em>{{projectTaxCreditUsage.amount}}</em> </p>
        <p matLine>TaxYear - <em>{{projectTaxCreditUsage.taxYear}}</em> </p>
        <p matLine>TaxState - <em>{{projectTaxCreditUsage.taxState}}</em> </p>
        <p matLine>Comment - <em>{{projectTaxCreditUsage.comment}}</em> </p>
        <p matLine>Client - <em>{{projectTaxCreditUsage.Project?.Project?.Client?.name}}</em></p>
        <p matLine>Created - <em>{{projectTaxCreditUsage.createdAt | date:'medium'}}</em> </p>
        <p matLine>Updated - <em>{{projectTaxCreditUsage.updatedAt | date:'medium'}}</em> </p>   
    </a>
</mat-nav-list> -->


<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- internalId Column -->
    <ng-container matColumnDef="internalId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let row"> <a [routerLink]="['/project-tax-credit-usage', row.id]"> <mat-icon aria-hidden="false" aria-label="launch">launch</mat-icon> </a>{{row.internalId}} </td>
    </ng-container>

    <!-- clientName Column -->
    <ng-container matColumnDef="clientName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Name </th>
        <td mat-cell *matCellDef="let row"> {{row.clientName}} </td>
      </ng-container>
      
   <!-- projectName Column -->
   <ng-container matColumnDef="projectName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Project Name </th>
    <td mat-cell *matCellDef="let row"> {{row.projectName}} </td>
   </ng-container>

    <!-- type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td mat-cell *matCellDef="let row"> {{row.type}} </td>
    </ng-container>
    
    <!-- amount Column -->
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
        <td mat-cell *matCellDef="let row">  {{row.amount}} </td>
    </ng-container>
      
    <!-- taxYear Column -->
    <ng-container matColumnDef="taxYear">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tax Year </th>
        <td mat-cell *matCellDef="let row">  {{row.taxYear}} </td>
    </ng-container>

    <!-- taxState Column -->
    <ng-container matColumnDef="taxState">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tax State </th>
        <td mat-cell *matCellDef="let row">  {{row.taxState}} </td>
    </ng-container>

    <!-- comment Column -->
    <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Comment </th>
        <td mat-cell *matCellDef="let row">  {{row.comment}} </td>
    </ng-container>
    
      <!-- clientName Column -->
      <ng-container matColumnDef="updated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
          <td mat-cell *matCellDef="let row"> {{row.updatedAt | date:'medium'}} </td>
        </ng-container>
      
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


<button mat-fab class="mat-fab-bottom-right" color="accent" [routerLink]="['/newproject-tax-credit-usage']">
    <mat-icon>add</mat-icon>
</button>
