import { Injectable } from '@angular/core';
import { CustomError, CustomErrorNames} from '../../common/CustomErrors'
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NoticebarService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private snackBar:MatSnackBar) { }
  openNoticeBar(err:any) {
    let errorToDisplay:string;
    if ( err instanceof CustomError) {
      console.log(err);
      errorToDisplay = err.name;
    } 
    else if ( err?.errors[0]?.errorType == "DynamoDB:ConditionalCheckFailedException") {
      errorToDisplay = "User is not Authorized to Update";
    } else {
      errorToDisplay = "Unknown Error. Check developer console log for  more info!";
      console.log(err);
    }

    this.snackBar.open(errorToDisplay, 'close', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
