<h2 *ngIf="editMode">Edit project asset</h2>
<h2 *ngIf="!editMode">Add a project asset</h2>

<form [formGroup]="form">
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>InternalId</span></mat-label>
            <input matInput type="string" formControlName="internalId" value="Just a placeholder"> 
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>Type</span></mat-label>
            <mat-select formControlName="type">
                <mat-option value="Usecase">Usecase</mat-option>
                <mat-option value="Feature">Feature</mat-option>
                <mat-option value="UserStory">UserStory</mat-option>
                <mat-option value="Module">Module</mat-option>
                <mat-option value="Component">Component</mat-option>
              </mat-select>  
        </mat-form-field>
    </div>

    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>Name</span></mat-label>
            <input matInput type="string" formControlName="name">
            <mat-error *ngIf="form.get('name').hasError('required')">Name is required</mat-error>
        </mat-form-field>
    </div>

    <div>
        <app-project-select formControlName="projectID"></app-project-select>
    </div>

    <div class="buttonbar">
        <button *ngIf="!editMode" mat-raised-button color="primary" (click)="add()">Add</button>
        <button *ngIf="editMode" mat-raised-button color="warn" (click)="delete()">Delete</button>
        <button *ngIf="editMode" mat-raised-button color="primary" (click)="update()">Update</button>
    </div>
</form>
