import { Component, OnInit } from '@angular/core';
import { APIService, ListProjectAssetsQuery, OnUpdateProjectAssetSubscription } from 'src/app/API.service';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { NoticebarService } from '../services/noticebar.service';

@Component({
  selector: 'app-download-client',
  templateUrl: './download-client.component.html',
  styleUrls: ['./download-client.component.css']
})
export class DownloadClientComponent implements OnInit {

  projectAssets: any[];
  subscription: Subscription;
  projectAssetsString: string;

  constructor(
    private router: Router,
    private api: APIService,
    private noticeBar: NoticebarService) { }

  ngOnInit(): void {
    this.listProjectAssets();
  }

  listProjectAssets() {
    this.api.ListProjectAssets().then((list: ListProjectAssetsQuery) => {
      if (list.items.length == 0) {
        this.router.navigate(['newproject-asset']);
        return;
      }
      this.projectAssets = list.items.sort(this.byCreatedAt);
      this.projectAssetsString = JSON.stringify(this.projectAssets,null,2);
      console.log(this.projectAssets)
    });
  }
   // sort by createdAt, newest first
   byCreatedAt(a, b) {
    return a.createdAt < b.createdAt ? 1 : -1;
  }
}
