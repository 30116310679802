<!-- <mat-nav-list>
    <a *ngFor="let project of projects" mat-list-item [routerLink]="['/project', project.id]">
        <h3 matLine><span *ngIf="project.internalId"><em>{{project.internalId}}</em></span></h3>
        <p matLine>Project Name - <b>{{project.name}}</b></p>
        <p matLine>Project Contact Name - <b>{{project.contactName}}</b></p>
         <p matLine>Client - <em>{{project.Client?.name}}</em></p>
         <p matLine>Created - <em>{{project.createdAt | date:'medium'}}</em> </p>
         <p matLine>Updated - <em>{{project.updatedAt | date:'medium'}}</em> </p>   
    </a>
</mat-nav-list> -->

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="internalId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
    <td mat-cell *matCellDef="let row"> <a [routerLink]="['/project', row.id]"> <mat-icon aria-hidden="false" aria-label="launch">launch</mat-icon> </a>{{row.internalId}} </td>
  </ng-container>

  

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
    <td mat-cell *matCellDef="let row">  {{row.name}} </td>
  </ng-container>

  <!-- contactName Column -->
  <ng-container matColumnDef="contactName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact Name </th>
    <td mat-cell *matCellDef="let row"> {{row.contactName}} </td>
  </ng-container>

  <!-- clientName Column -->
  <ng-container matColumnDef="clientName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Name </th>
    <td mat-cell *matCellDef="let row"> {{row.Client?.name}} </td>
  </ng-container>

  <!-- clientName Column -->
  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
    <td mat-cell *matCellDef="let row"> {{row.createdAt | date:'medium'}} </td>
  </ng-container>

    <!-- clientName Column -->
    <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
        <td mat-cell *matCellDef="let row"> {{row.updatedAt | date:'medium'}} </td>
      </ng-container>
    

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


<button mat-fab class="mat-fab-bottom-right" color="accent" [routerLink]="['/newproject']">
    <mat-icon>add</mat-icon>
</button>
