<!-- <mat-nav-list>
    <a *ngFor="let projectBudget of projectBudgets" mat-list-item [routerLink]="['/project-budget', projectBudget.id]">
        <h3 matLine><span *ngIf="projectBudget.internalId">{{projectBudget.internalId}} </span> </h3>

        <p matLine>Project Module - <b>{{projectBudget.Project?.name}}</b></p>
        <p matLine>Project Module Type - <em>{{projectBudget.Project?.type}}</em></p>
        <p matLine>Project Module Budget Hours - <em>{{projectBudget.budgetHours}}</em> </p>
        <p matLine>Project Module Budget Amount - <em>{{projectBudget.budgetAmount}}</em> </p>
        <p matLine>Project Module Tax Credit Percent - <em>{{projectBudget.taxCreditPercent}}</em> </p>
        <p matLine>Project Module Tax Credit Hours - <em>{{projectBudget.taxCreditHours}}</em> </p>
        <p matLine>Project Module Tax Credit Amount - <em>{{projectBudget.taxCreditAmount}}</em> </p>
        <p matLine>Project Module Total Tax Credit - <em>{{projectBudget.totalTaxCredit}}</em> </p>
        <p matLine>Client - <em>{{projectBudget.Project?.Project?.Client?.name}}</em></p>
        <p matLine>Created - <em>{{projectBudget.createdAt | date:'medium'}}</em> </p>
        <p matLine>Updated - <em>{{projectBudget.updatedAt | date:'medium'}}</em> </p>   
    </a>
</mat-nav-list> -->

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- internalId Column -->
    <ng-container matColumnDef="internalId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let row"> <a [routerLink]="['/project-budget', row.id]"> <mat-icon aria-hidden="false" aria-label="launch">launch</mat-icon> </a>{{row.internalId}} </td>
    </ng-container>

    <!-- clientName Column -->
    <ng-container matColumnDef="clientName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Name </th>
        <td mat-cell *matCellDef="let row"> {{row.clientName}} </td>
      </ng-container>
      
   <!-- projectName Column -->
   <ng-container matColumnDef="projectName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Project Name </th>
    <td mat-cell *matCellDef="let row"> {{row.projectName}} </td>
   </ng-container>

    <!-- budgetHours Column -->
    <ng-container matColumnDef="budgetHours">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Budget Hours </th>
        <td mat-cell *matCellDef="let row">  {{row.budgetHours}} </td>
    </ng-container>
      
    <!-- budgetAmount Column -->
    <ng-container matColumnDef="budgetAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Budget Amount </th>
        <td mat-cell *matCellDef="let row">  {{row.budgetAmount}} </td>
    </ng-container>

    <!-- taxCreditHours Column -->
    <ng-container matColumnDef="taxCreditHours">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tax Credit Hours </th>
        <td mat-cell *matCellDef="let row">  {{row.taxCreditHours}} </td>
    </ng-container>

    <!-- taxCreditAmount Column -->
    <ng-container matColumnDef="taxCreditAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tax Credit Amount </th>
        <td mat-cell *matCellDef="let row">  {{row.taxCreditAmount}} </td>
    </ng-container>
    
      <!-- clientName Column -->
      <ng-container matColumnDef="updated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
          <td mat-cell *matCellDef="let row"> {{row.updatedAt | date:'medium'}} </td>
        </ng-container>
      
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

<button mat-fab class="mat-fab-bottom-right" color="accent" [routerLink]="['/newproject-budget']">
    <mat-icon>add</mat-icon>
</button>

