import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService, GetProjectTaxCreditUsageQuery } from 'src/app/API.service';
import { NoticebarService } from '../services/noticebar.service';

@Component({
  selector: 'app-project-tax-credit-usage',
  templateUrl: './project-tax-credit-usage.component.html',
  styleUrls: ['./project-tax-credit-usage.component.css']
})
export class ProjectTaxCreditUsageComponent implements OnInit {

  editMode: boolean;
  form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: APIService,
    private noticeBar: NoticebarService) { }

  ngOnInit(): void {
    const now = new Date(); 
    const iid = "PTCU-" + formatDate(now, "yyyy-MM-dd-HH-mm-ss-SSS", "en-US");

    this.form = new FormGroup({
     id:  new FormControl(null),
    //  internalId:  new FormControl("DefaultInternalID",[Validators.required]),
     internalId:  new FormControl(iid, [Validators.required]),
     type: new FormControl(null, [Validators.required]),
     amount: new FormControl(null, [Validators.required]),
     comment: new FormControl(null, [Validators.required]),
     taxYear: new FormControl(null, [Validators.required]),
     taxState: new FormControl(null, [Validators.required]),
     projectID: new FormControl(null),
    });


    this.route.params.subscribe(params => {
      this.editMode = 'id' in params;
      if (this.editMode) {
        this.loadProjectTaxCreditUsage(params['id']);
      }
    });
  }

  loadProjectTaxCreditUsage(id: string) {
    this.api.GetProjectTaxCreditUsage(id).then((taxCreditUsage: GetProjectTaxCreditUsageQuery) => {
      this.form.patchValue({
        id: taxCreditUsage.id, 
        internalId: taxCreditUsage.internalId, 
        type: taxCreditUsage.type, 
        amount: taxCreditUsage.amount, 
        comment: taxCreditUsage.comment, 
        taxYear: taxCreditUsage.taxYear, 
        taxState: taxCreditUsage.taxState, 
        projectID: taxCreditUsage.projectID, 
      })
    });
  }

  async getClientNameByProjectId(id: string) {
    return this.api.GetProject(id)
      .then(project => project.Client.name)
      .then( clientName => {console.log(clientName); return clientName;})
  }

  async add() {
    if (this.form.status === 'VALID') {
      const clientName = await this.getClientNameByProjectId(this.form.value.projectID);
      console.log(this.form.value);
      const projectData = {
        ...this.form.value,
        ...{ editGroup : "irc41-" + clientName + "-edit" , viewGroup : "irc41-" + clientName + "-view"}
      }      // handle the form data here. ex: send data to server etc.
      this.api.CreateProjectTaxCreditUsage(this.form.value).then(() => {
       this.router.navigate(['project-tax-credit-usages']);
     })
    }
  }

  update() {
    if (this.form.status === 'VALID') {
      this.api.UpdateProjectTaxCreditUsage(this.form.value).then(() => {
        this.router.navigate(['project-tax-credit-usages']);
      })
      .catch(err => this.noticeBar.openNoticeBar(err))
    }
  }

  delete() {
    this.api.DeleteProjectTaxCreditUsage({ id: this.form.value['id'] }).then(() => {
      this.router.navigate(['project-tax-credit-usages']);
    })
    .catch(err => this.noticeBar.openNoticeBar(err))
  }

}
