<mat-nav-list>
  <a
    *ngFor="let s3file of s3files"
    mat-list-item
    [routerLink]="['/s3file', s3file.id]"
  >
    <h3 matLine>
      {{ s3file.s3key }} {{ s3file.createdAt | date: "shortDate" }}
    </h3>
    <p matLine>{{ s3file.s3IdentityId }}</p>
    <!-- <p>{{ getClientImageUrl(s3file.s3key, s3file.s3IdentityId) | async }}</p> -->

    <!-- <div class="projectImage">
      <img
        src="{{ getClientImageUrl(s3file.s3key, s3file.s3IdentityId) }}"
        alt="no-image"
      />
    </div> -->
  </a>
</mat-nav-list>

<button
  mat-fab
  class="mat-fab-bottom-right"
  color="accent"
  [routerLink]="['/news3file']"
>
  <mat-icon>add</mat-icon>
</button>
