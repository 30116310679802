import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ClientsComponent } from './clients/clients.component';
import { ClientComponent } from './client/client.component';
import { ProjectComponent } from './project/project.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectAssetComponent } from './project-asset/project-asset.component';
import { ProjectAssetsComponent } from './project-assets/project-assets.component';
import { TaxCreditNoteComponent } from './tax-credit-note/tax-credit-note.component';
import { TaxCreditNotesComponent } from './tax-credit-notes/tax-credit-notes.component';
// import { ProjectAssetBudgetComponent } from './project-asset-budget/project-asset-budget.component';
// import { ProjectAssetBudgetsComponent } from './project-asset-budgets/project-asset-budgets.component';
//import { TaxCreditUsageComponent } from './tax-credit-usage/tax-credit-usage.component';
//import { TaxCreditUsagesComponent } from './tax-credit-usages/tax-credit-usages.component';
import { ClientNotesComponent } from './client-notes/client-notes.component';
import { ClientNoteComponent } from './client-note/client-note.component';
import { DownloadClientComponent } from './download-client/download-client.component';
import { JunglegymComponent } from './junglegym/junglegym.component';
import { ProjectBudgetComponent } from './project-budget/project-budget.component';
import { ProjectBudgetsComponent } from './project-budgets/project-budgets.component';
import { ProjectTaxCreditUsageComponent } from './project-tax-credit-usage/project-tax-credit-usage.component';
import { ProjectTaxCreditUsagesComponent } from './project-tax-credit-usages/project-tax-credit-usages.component';
import { Irc41ReportComponent } from './irc41-report/irc41-report.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { S3fileComponent } from './s3file/s3file.component';
import { S3filesComponent } from './s3files/s3files.component';

const routes: Routes = [
  {
    path: 'clients',
    component: ClientsComponent,
  },
  {
    path: 'client/:id',
    component: ClientComponent,
  },
  {
    path: 'newclient',
    component: ClientComponent,
  },
  {
    path: 'projects',
    component: ProjectsComponent,
  },
  {
    path: 'project/:id',
    component: ProjectComponent,
  },
  {
    path: 'newproject',
    component: ProjectComponent,
  },
  {
    path: 'project-assets',
    component: ProjectAssetsComponent,
  },
  {
    path: 'project-asset/:id',
    component: ProjectAssetComponent,
  },
  {
    path: 'newproject-asset',
    component: ProjectAssetComponent,
  },
  {
    path: 'tax-credit-notes',
    component: TaxCreditNotesComponent,
  },
  {
    path: 'tax-credit-note/:id',
    component: TaxCreditNoteComponent,
  },
  {
    path: 'newtax-credit-note',
    component: TaxCreditNoteComponent,
  },
  {
    path: 'project-budgets',
    component: ProjectBudgetsComponent,
  },
  {
    path: 'project-budget/:id',
    component: ProjectBudgetComponent,
  },
  {
    path: 'newproject-budget',
    component: ProjectBudgetComponent,
  },
  {
    path: 'project-tax-credit-usages',
    component: ProjectTaxCreditUsagesComponent,
  },
  {
    path: 'project-tax-credit-usage/:id',
    component: ProjectTaxCreditUsageComponent,
  },
  {
    path: 'newproject-tax-credit-usage',
    component: ProjectTaxCreditUsageComponent,
  },
  {
    path: 'client-notes',
    component: ClientNotesComponent,
  },
  {
    path: 'client-note/:id',
    component: ClientNoteComponent,
  },
  {
    path: 'newclient-note',
    component: ClientNoteComponent,
  },
  {
    path: 'download-client',
    component: DownloadClientComponent,
  },
  {
    path: 'irc41-report',
    component: Irc41ReportComponent,
  },
  {
    path: 'file-manager',
    component: FileManagerComponent,
  },
  {
    path: 'junglegym',
    component: JunglegymComponent,
  },
  {
    path: 's3files',
    component: S3filesComponent,
  },
  {
    path: 's3file/:id',
    component: S3fileComponent,
  },
  {
    path: 'news3file',
    component: S3fileComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
