/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://he7qdfqfmfdjjhloyo7veruwya.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-mqx7gt42rjejpmjrm2i3y4mqxe",
    "aws_cognito_identity_pool_id": "us-east-2:e50a46cf-dd2e-4467-8031-97985bc8c401",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_HSecCZEF1",
    "aws_user_pools_web_client_id": "1h0p36bef89clb8jlheo7jjf54",
    "oauth": {},
    "aws_user_files_s3_bucket": "irc41clientimages-bucket215804-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
