<!-- <mat-nav-list>
    <a *ngFor="let projectAsset of projectAssets" mat-list-item [routerLink]="['/project-asset', projectAsset.id]">
        <h3 matLine><span *ngIf="projectAsset.internalId"><em>{{projectAsset.internalId}}</em> </span></h3>
        <p matLine>Project Module - <b>{{projectAsset.name}}</b></p>
        <p matLine>Project - <b>{{projectAsset.Project.name}}</b></p>
        <p matLine>Client - {{projectAsset.Project.Client.name}}</p>
        <p matLine>Created - {{projectAsset.createdAt | date:'medium'}} </p>
        <p matLine>Updated - {{projectAsset.updatedAt | date:'medium'}} </p>
    </a>
</mat-nav-list> -->

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="internalId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let row"> <a [routerLink]="['/project-asset', row.id]"> <mat-icon aria-hidden="false" aria-label="launch">launch</mat-icon> </a>{{row.internalId}} </td>
    </ng-container>
    
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Module Name </th>
      <td mat-cell *matCellDef="let row">  {{row.name}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Module Type </th>
        <td mat-cell *matCellDef="let row">  {{row.type}} </td>
    </ng-container>
      
    <!-- contactName Column -->
    <ng-container matColumnDef="projectName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Project Name </th>
      <td mat-cell *matCellDef="let row"> {{row.projectName}} </td>
    </ng-container>
  
    <!-- clientName Column -->
    <ng-container matColumnDef="clientName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Name </th>
      <td mat-cell *matCellDef="let row"> {{row.clientName}} </td>
    </ng-container>
  
    <!-- clientName Column -->
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
      <td mat-cell *matCellDef="let row"> {{row.createdAt | date:'medium'}} </td>
    </ng-container>
  
      <!-- clientName Column -->
      <ng-container matColumnDef="updated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
          <td mat-cell *matCellDef="let row"> {{row.updatedAt | date:'medium'}} </td>
        </ng-container>
      
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  

<button mat-fab class="mat-fab-bottom-right" color="accent" [routerLink]="['/newproject-asset']">
    <mat-icon>add</mat-icon>
</button>
