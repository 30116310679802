<h2 *ngIf="editMode">Edit Project Budget</h2>
<h2 *ngIf="!editMode">Add a Project Budget</h2>

<form [formGroup]="form">
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>InternalId</span></mat-label>
            <input matInput type="string" formControlName="internalId">
        </mat-form-field>
    </div>

    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>BudgetHours</span></mat-label>
            <input matInput type="string" formControlName="budgetHours">
            <mat-error *ngIf="form.get('budgetHours').hasError('required')">Budget Hours is required</mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>BudgetAmount</span></mat-label>
            <input matInput type="string" formControlName="budgetAmount">
            <mat-error *ngIf="form.get('budgetAmount').hasError('required')">budgetAmount is required</mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>TaxCreditPercent</span></mat-label>
            <input matInput type="string" formControlName="taxCreditPercent">
            <mat-error *ngIf="form.get('taxCreditPercent').hasError('required')">Tax Credit Percent is required</mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>TaxCreditHours</span></mat-label>
            <input matInput type="string" formControlName="taxCreditHours">
            <mat-error *ngIf="form.get('taxCreditHours').hasError('required')">Tax Credit Hours is required</mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>TaxCreditAmount</span></mat-label>
            <input matInput type="string" formControlName="taxCreditAmount">
            <mat-error *ngIf="form.get('taxCreditAmount').hasError('required')">Tax Credit Amount is required</mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>TotalTaxCredit</span></mat-label>
            <input matInput type="string" formControlName="totalTaxCredit">
            <mat-error *ngIf="form.get('totalTaxCredit').hasError('required')">Total Tax Credit is required</mat-error>
        </mat-form-field>
    </div>

    <div>
        <app-project-select formControlName="projectID"></app-project-select>
    </div>

    <div class="buttonbar">
        <button *ngIf="!editMode" mat-raised-button color="primary" (click)="add()">Add</button>
        <button *ngIf="editMode" mat-raised-button color="warn" (click)="delete()">Delete</button>
        <button *ngIf="editMode" mat-raised-button color="primary" (click)="update()">Update</button>
    </div>
</form>
