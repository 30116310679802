import { Component, OnInit, OnDestroy } from '@angular/core';
import { APIService, ListTaxCreditNotesQuery, OnUpdateTaxCreditNoteSubscription } from 'src/app/API.service';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { API, graphqlOperation, Auth } from 'aws-amplify';

@Component({
  selector: 'app-tax-credit-notes',
  templateUrl: './tax-credit-notes.component.html',
  styleUrls: ['./tax-credit-notes.component.css']
})
export class TaxCreditNotesComponent implements OnInit {


  taxCreditNotes: any[];
  subscription: Subscription;

  constructor(
    private router: Router,
    private api: APIService) { }

  ngOnInit(): void {
    //this.listenChanges();
    this.listTaxCreditNotes();
  }

  listTaxCreditNotes() {
    this.api.ListTaxCreditNotes().then((list: ListTaxCreditNotesQuery) => {
      if (list.items.length == 0) {
        this.router.navigate(['newtax-credit-note']);
        return;
      }
      this.taxCreditNotes = list.items.sort(this.byCreatedAt);
    });
  }
/*
  // listen changes on taxCreditNotes
  listenChanges() {
    Auth.currentAuthenticatedUser().then(user => {
      const OnUpdateTaxCreditNoteListener: Observable<any> = API.graphql(
        graphqlOperation(
          `subscription OnUpdateTaxCreditNote($owner: String!) {
            onUpdateTaxCreditNote(owner: $owner) {
              __typename
              id
              serialnum
              title
              customerID
              customer {
                __typename
                id
                name
                address
                siret
                taxCreditNotes {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              lines {
                __typename
                items {
                  __typename
                  id
                  taxCreditNoteID
                  title
                  quantity
                  cost
                  createdAt
                  updatedAt
                  owner
                }
                nextToken
              }
              createdAt
              owner
              updatedAt
            }
          }`, { owner: user.username }
        )
      ) as any;
      this.subscription = OnUpdateTaxCreditNoteListener.subscribe(() => {
        this.listTaxCreditNotes();
      });
    });
  }
*/

  // sort by createdAt, newest first
  byCreatedAt(a, b) {
    return a.createdAt < b.createdAt ? 1 : -1;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
