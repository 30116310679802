<!-- <mat-nav-list>
    <a *ngFor="let taxCreditNote of taxCreditNotes" mat-list-item [routerLink]="['/tax-credit-note', taxCreditNote.id]">
        <h3 matLine><span *ngIf="taxCreditNote.internalId">{{taxCreditNote.internalId}} </span></h3>

        <p matLine>IRC41 Category - <b>{{taxCreditNote.irc41Category}}</b> </p>
        <p matLine>Project Module - <b>{{taxCreditNote.ProjectAsset?.name}}</b></p>
        <p matLine>Project Module Type - <em>{{taxCreditNote.ProjectAsset?.type}}</em></p>
        <p matLine><span>Documentation</span> - <span>{{taxCreditNote.note}} </span></p>
        <p matLine>Client - <em>{{taxCreditNote.ProjectAsset?.Project?.Client?.name}}</em></p>
        <p matLine>Created - <em>{{taxCreditNote.createdAt | date:'medium'}}</em> </p>
        <p matLine>Updated - <em>{{taxCreditNote.updatedAt | date:'medium'}}</em> </p>   
    </a>
</mat-nav-list> -->

<!-- <mat-nav-list> -->
    <mat-card class="tax-credit-notes" *ngFor="let taxCreditNote of taxCreditNotes" mat-list-item [routerLink]="['/tax-credit-note', taxCreditNote.id]">
        <!-- <a *ngFor="let taxCreditNote of taxCreditNotes" mat-list-item [routerLink]="['/tax-credit-note', taxCreditNote.id]"> -->
        <!-- <mat-card-title> -->
        <h3><span *ngIf="taxCreditNote.internalId">{{taxCreditNote.internalId}} </span></h3>
        <p>Project - <b>{{taxCreditNote.ProjectAsset?.Project?.name}}</b></p>
        <p>Project Module - <b>{{taxCreditNote.ProjectAsset?.name}}</b></p>
        <p>Project Module Type - <em>{{taxCreditNote.ProjectAsset?.type}}</em></p>
        <p>IRC41 Category - <b>{{taxCreditNote.irc41Category}}</b> </p>
        <!-- </mat-card-title> -->
        <mat-card><p><span>{{taxCreditNote.note}} </span></p>
        </mat-card>
        <p>Client - <em>{{taxCreditNote.ProjectAsset?.Project?.Client?.name}}</em></p>
        <p>Created - <em>{{taxCreditNote.createdAt | date:'medium'}}</em> </p>
        <p>Updated - <em>{{taxCreditNote.updatedAt | date:'medium'}}</em> </p>   
        <!-- </a> -->
    </mat-card>
<!-- </mat-nav-list> -->

<button mat-fab class="mat-fab-bottom-right" color="accent" [routerLink]="['/newtax-credit-note']">
    <mat-icon>add</mat-icon>
</button>
