<amplify-authenticator>
  <main>
    <mat-toolbar color="primary" class="donotprint"
      ><span>IRONPACT</span>
      <span class="fill-remaining-space"></span>
      <a mat-button [routerLink]="['/clients']">Clients</a>
      <a mat-button [routerLink]="['/projects']">Projects</a>
      <a mat-button [routerLink]="['/project-assets']">Modules</a>
      <a mat-button [routerLink]="['/tax-credit-notes']">IRC41-Doc</a>
      <a mat-button [routerLink]="['/project-budgets']">Financials</a>
      <a mat-button [routerLink]="['/project-tax-credit-usages']"
        >Tax Credit Usage</a
      >
      <a mat-button [routerLink]="['/client-notes']">Todo Notes</a>
      <a mat-button [routerLink]="['/irc41-report']">Irc41-Report</a>
      <a mat-button [routerLink]="['/download-client']">Download</a>
      <!-- <a mat-button [routerLink]="['/file-manager']">Upload Image</a> -->
      <a mat-button [routerLink]="['/s3files']"> Images</a>

      <amplify-sign-out></amplify-sign-out>
    </mat-toolbar>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </main>
</amplify-authenticator>
