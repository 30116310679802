import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService, GetS3FileQuery, S3File } from 'src/app/API.service';
import { CustomError, CustomErrorNames } from 'src/common/CustomErrors';
import { NoticebarService } from '../services/noticebar.service';
import { S3Service } from '../services/s3.service';

@Component({
  selector: 'app-s3file',
  templateUrl: './s3file.component.html',
  styleUrls: ['./s3file.component.css'],
})
export class S3fileComponent implements OnInit {
  editMode: boolean;
  form: FormGroup;
  s3file: S3File;
  s3ImageUrl: any;
  selectedFile: File;
  identityId: string;
  iid: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: APIService,
    private noticeBar: NoticebarService,
    private s3Service: S3Service
  ) {}

  ngOnInit(): void {
    const now = new Date();
    this.iid = 'F-' + formatDate(now, 'yyyy-MM-dd-HH-mm-ss-SSS', 'en-US');

    this.form = new FormGroup({
      id: new FormControl(null),
      clientID: new FormControl(null),
      internalId: new FormControl(this.iid, [Validators.required]),
    });

    this.s3Service.getIdentityId().subscribe((data) => {
      this.identityId = data;
      console.log(data);
    });

    this.route.params.subscribe((params) => {
      this.editMode = 'id' in params;
      if (this.editMode) {
        this.loadS3File(params['id']);
      }
    });
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  loadS3File(id: string) {
    this.api.GetS3File(id).then(async (s3file: GetS3FileQuery) => {
      console.log(s3file);
      this.s3file = s3file;
      this.form.patchValue({
        id: s3file.id,
        clientID: s3file.clientID,
        internalId: s3file.internalId,
      });
      this.s3ImageUrl = await this.s3Service.getImageURL(
        s3file.s3key,
        s3file.s3IdentityId
      );
      console.log(this.s3ImageUrl);
    });
  }

  async getClientName(id: string) {
    return this.api.GetClient(id).then((client) => client.name);
  }

  async add() {
    if (this.form.status === 'VALID') {
      const clientName = await this.getClientName(this.form.value.clientID);
      console.log(this.form.value);
      const fileName = this.iid + this.selectedFile.name;
      const s3response = await this.s3Service.uploadProtectedFile(
        fileName,
        this.selectedFile
      );

      const s3Data = {
        internalId: this.form.value.internalId,
        s3key: fileName,
        s3IdentityId: this.identityId,
        clientID: this.form.value.clientID,
        editGroup: 'irc41-' + clientName + '-edit',
        viewGroup: 'irc41-' + clientName + '-view',
      };

      await this.api.CreateS3File(s3Data).then(() => {
        this.router.navigate(['s3files']);
      });
      console.log(s3response);
    }
  }

  async update() {
    if (this.form.status === 'VALID') {
      const s3response = await this.s3Service.updateProtectedFile(
        this.s3file.s3key,
        this.selectedFile
      );

      this.api
        .UpdateS3File(this.form.value)
        .then(() => {
          this.router.navigate(['s3files']);
        })
        .catch((err) => this.noticeBar.openNoticeBar(err));
    }
  }

  async delete() {
    console.log(this.s3file);

    const s3response = await this.s3Service.deleteProtectedFile(
      this.s3file.s3key,
      this.s3file.s3IdentityId
    );

    this.api
      .DeleteS3File({ id: this.form.value['id'] })
      .then(() => {
        this.router.navigate(['s3files']);
      })
      .catch((err) => this.noticeBar.openNoticeBar(err));
  }
}
