import { Injectable } from '@angular/core';
import { Auth, Storage } from 'aws-amplify';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class S3Service {
  constructor() {}

  async uploadProtectedFile(fileName, file, progressCallback?) {
    if (!progressCallback) {
      progressCallback = (progress) =>
        console.log(`uploaded: ${progress.total / progress.total}`);
    }
    const s3Object = await Storage.put(fileName, file, {
      progressCallback,
      level: 'protected',
    });
    return s3Object;
  }

  async deleteProtectedFile(fileName, progressCallback?) {
    if (!progressCallback) {
      progressCallback = (progress) =>
        console.log(`removed: ${progress.total / progress.total}`);
    }
    const s3Object = await Storage.remove(fileName, {
      progressCallback,
      level: 'protected',
    });
    return s3Object;
  }

  async updateProtectedFile(fileName, file, progressCallback?) {
    return this.uploadProtectedFile(fileName, file, progressCallback);
  }

  async getImageURL(imageKey: string, identityId: string) {
    console.log('S3 ImageKey', imageKey);
    const imageURL = await Storage.get(imageKey, {
      level: 'protected',
      identityId,
      //'us-east-2:6cceb450-29c6-49b5-88db-eef6defebd88',
    }).catch((err) => {
      console.log('Error in getImageURL', err);
      return 'Error';
    });
    console.log('S3 ImageURL', imageURL);
    return imageURL;
  }
  getIdentityId() {
    return from(Auth.currentUserCredentials()).pipe(
      map((user) => user.identityId)
    );
  }
}
