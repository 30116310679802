<h2 *ngIf="editMode">Edit Client Note</h2>
<h2 *ngIf="!editMode">Add a Client Note</h2>

<form [formGroup]="form">
    <div>
        <mat-form-field [style.width.px]="512">
            <mat-label><span>InternalId</span></mat-label>
            <input matInput type="string" formControlName="internalId" readonly>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [style.width.px]="1024">
            <mat-label><span>Note</span></mat-label>
            <textarea matInput formControlName="note" rows="16"></textarea>
            <mat-error *ngIf="form.get('note').hasError('required')">Note is required</mat-error>
        </mat-form-field>
    </div>

    <div>
        <app-client-select formControlName="clientID"></app-client-select>
    </div>

    <div class="buttonbar">
        <button *ngIf="!editMode" mat-raised-button color="primary" (click)="add()">Add</button>
        <button *ngIf="editMode" mat-raised-button color="warn" (click)="delete()">Delete</button>
        <button *ngIf="editMode" mat-raised-button color="primary" (click)="update()">Update</button>
    </div>
</form>