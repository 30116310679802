import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService, GetProjectAssetBudgetQuery } from 'src/app/API.service';
import { NoticebarService } from '../services/noticebar.service';

@Component({
  selector: 'app-project-asset-budget',
  templateUrl: './project-asset-budget.component.html',
  styleUrls: ['./project-asset-budget.component.css']
})
export class ProjectAssetBudgetComponent implements OnInit {

  editMode: boolean;
  form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: APIService,
    private noticeBar: NoticebarService) { }

  ngOnInit(): void {
    const now = new Date(); 
    const iid = "PAB-" + formatDate(now, "yyyy-MM-dd-HH-mm-ss-SSS", "en-US");

    this.form = new FormGroup({
      id: new FormControl(null),
      internalId: new FormControl(iid, [Validators.required]),
      budgetHours: new FormControl(null, [Validators.required]),
      budgetAmount: new FormControl(null, [Validators.required]),
      taxCreditPercent: new FormControl(null, [Validators.required]),
      taxCreditHours: new FormControl(null, [Validators.required]),
      taxCreditAmount: new FormControl(null, [Validators.required]),
      totalTaxCredit: new FormControl(null, [Validators.required]),
      projectassetID: new FormControl(null),
    });

    this.route.params.subscribe(params => {
      this.editMode = 'id' in params;
      if (this.editMode) {
        this.loadProjectAssetBudget(params['id']);
      }
    });
  }

  loadProjectAssetBudget(id: string) {
    this.api.GetProjectAssetBudget(id).then((projectAssetBudget: GetProjectAssetBudgetQuery) => {
      this.form.patchValue({
        id: projectAssetBudget.id,
        internalId: projectAssetBudget.internalId,
        budgetHours: projectAssetBudget.budgetHours,
        budgetAmount: projectAssetBudget.budgetAmount,
        taxCreditPercent: projectAssetBudget.taxCreditPercent,
        taxCreditHours: projectAssetBudget.taxCreditHours,
        taxCreditAmount: projectAssetBudget.taxCreditAmount,
        totalTaxCredit: projectAssetBudget.totalTaxCredit,
        projectassetID: projectAssetBudget.projectassetID,
      })
    });
  }

  add() {
    if (this.form.status === 'VALID') {
      this.api.CreateProjectAssetBudget(this.form.value).then(() => {
        this.router.navigate(['project-asset-budgets']);
      })
    }
  }

  update() {
    if (this.form.status === 'VALID') {
      this.api.UpdateProjectAssetBudget(this.form.value).then(() => {
        this.router.navigate(['project-asset-budgets']);
      })
      .catch(err => this.noticeBar.openNoticeBar(err))
    }
  }

  delete() {
    this.api.DeleteProjectAssetBudget({ id: this.form.value['id'] }).then(() => {
      this.router.navigate(['project-asset-budgets']);
    })
    .catch(err => this.noticeBar.openNoticeBar(err))
  }

}
