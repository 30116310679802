<h2 *ngIf="editMode">Edit client</h2>
<h2 *ngIf="!editMode">Add a Client</h2>

<form [formGroup]="form">

    <div>
        <mat-form-field [style.width.px]="512">
            <mat-label><span>InternalId</span></mat-label>
            <input matInput type="string" formControlName="internalId" readonly>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>Name</span></mat-label>
            <input matInput type="string" formControlName="name">
            <mat-error *ngIf="form.get('name').hasError('required')">Name is required</mat-error>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>Street</span></mat-label>
            <input matInput type="string" formControlName="street">
            <mat-error *ngIf="form.get('street').hasError('required')">Street is required</mat-error>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>City</span></mat-label>
            <textarea matInput formControlName="city"></textarea>
            <mat-error *ngIf="form.get('city').hasError('required')">City is required</mat-error>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>Zip</span></mat-label>
            <textarea matInput formControlName="zip"></textarea>
            <mat-error *ngIf="form.get('zip').hasError('required')">Zip is required</mat-error>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field [style.width.px]="256">
            <mat-label><span>Country</span></mat-label>
            <textarea matInput formControlName="country"></textarea>
            <mat-error *ngIf="form.get('country').hasError('required')">Country is required</mat-error>
        </mat-form-field>
    </div>

    <div class="buttonbar">
        <button *ngIf="!editMode" mat-raised-button color="primary" (click)="add()">Add</button>
        <button *ngIf="editMode" mat-raised-button color="warn" (click)="delete()">Delete</button>
        <button *ngIf="editMode" mat-raised-button color="primary" (click)="update()">Update</button>
    </div>
</form>

